import React from "react";
import { Form } from "react-bootstrap";
import {baseMAUrl} from "../Constants";
import { useContext } from "react";
import { UserContext } from "../common/Context"

export const UserCrumbBar = (): React.ReactElement => {
  const userContext = useContext(UserContext);

  return (
    <div className="container-fluid crumb">
      <div className="container container-no-max-width" data-align="right">
        <div className="username" data-align="right">
          &nbsp;
          <>
            {userContext.user !== null && userContext.authorised && (
              <>
                {userContext.user.firstName} {userContext.user.lastName} ({userContext.user.email})&nbsp;
                <Form
                  id="logout_form"
                  className={'username p-0'}
                  action={baseMAUrl + '/auth/account/logout'}
                  method="GET"
                >
                  <Form.Control
                    className="username btn-link p-0 b-0 logout-link"
                    style={{ border: '0px', color: '#333333' }}
                    type="submit"
                    value="Logout"
                  />
                </Form>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
}