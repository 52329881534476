import React from "react";
import {Route, Routes} from "react-router-dom";
import Box from "@mui/material/Box";
import { ProductSideNavBar } from "../../components/ProductManager/Navigation";
import {SpecificationPage} from "./SpecificationPage";

export function SpecificationViewLayout(): JSX.Element {

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <ProductSideNavBar />
        <Routes>
          <Route index element={<SpecificationPage />} />
        </Routes>
      </Box>
    </div>
  )
}
