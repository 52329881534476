import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect} from "react";
import {Checkbox, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select} from "@mui/material";
import {InputChip} from "../Panel/InputChip";
import {toast} from "react-toastify";
import {ToolTipWithIcon} from "./ToolTipWithIcon";
import {RequiredTextField} from "./RequiredTextField";

export default function ParameterModal(props:any): any {
  const {addParaOpen, setAddParaOpen, data, index, setIndex, paraIndex, setParaIndex, handleSaveParameter, parameterIds} = props;
  const [paraId, setParaId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [checked, setChecked] = React.useState(true);
  const [idBeforeEdit, setIdBeforeEdit] = React.useState("");
  const [paraType, setParaType] = React.useState("INTEGER");
  // variables to store data for para type 'INTEGER' and 'FLOAT'
  const [defaultValue, setDefaultValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(50000);
  const [minValue, setMinValue] = React.useState(0);
  // variables to store data for para type 'BOOLEAN'
  const [defaultBool, setDefaultBool] = React.useState(false);
  // variables to store data for para type 'UTF8_STRING'
  const [maxLen, setMaxLen] = React.useState(500);
  const [minLen, setMinLen] = React.useState(0);
  const [defaultStr, setDefaultStr] = React.useState("");
  // variables to store data for para type 'LIST'
  const [maxItems, setMaxItems] = React.useState(10);
  const [minItems, setMinItems] = React.useState(1);
  const [defaultList, setDefaultList] = React.useState([]);
  const [itemType, setItemType] = React.useState("");
  const [idErrorText, setIdErrorText] = React.useState("");
  const [descriptionErrorText, setDescriptionErrorText] = React.useState("");
  const [nameErrorText, setNameErrorText] = React.useState("");
  const [defaultValueErrorText, setDefaultValueErrorText] = React.useState("");
  const [maxValueErrorText, setMaxValueErrorText] = React.useState("");
  const [minValueErrorText, setMinValueErrorText] = React.useState("");
  const [maxLenErrorText, setMaxLenErrorText] = React.useState("");
  const [minLenErrorText, setMinLenErrorText] = React.useState("");
  const [defaultStrErrorText, setDefaultStrErrorText] = React.useState("");
  const [maxItemsErrorText, setMaxItemsErrorText] = React.useState("");
  const [minItemsErrorText, setMinItemsErrorText] = React.useState("");
  const [defaultListErrorText, setDefaultListErrorText] = React.useState("");
  const [itemTypeErrorText, setItemTypeErrorText] = React.useState("");

  const handleChangeType = (event: any): any => {
    setParaType(event.target.value);
  };

  const handleChangeValue = (event:any, type:string): any => {
    if (paraType === "INTEGER"){
      if (type === "default"){
        setDefaultValue(parseInt(event.target.value));
      }
      else if(type === "max"){
        setMaxValue(parseInt(event.target.value));
      }
      else {
        setMinValue(parseInt(event.target.value));
      }
    }
    else if (paraType === "FLOAT"){
      if (type === "default"){
        setDefaultValue(parseFloat(event.target.value));
      }
      else if(type === "max"){
        setMaxValue(parseFloat(event.target.value));
      }
      else {
        setMinValue(parseFloat(event.target.value));
      }
    }
     else if (paraType === "UTF8_STRING"){
      if (type === "default"){
        setDefaultStr(event.target.value);
      }
      else if(type === "max"){
        setMaxLen(parseInt(event.target.value));
      }
      else {
        setMinLen(parseInt(event.target.value));
      }
    }
  };


  useEffect(() => {
    if(index === null || paraIndex === null){
      clearAllData();
    }
    else {
      const rowData = data[index];
      const paraData = rowData.parameters[paraIndex];
      setParaId(paraData.id);
      setIdBeforeEdit(paraData.id);
      setName(paraData.name);
      setDescription(paraData.description);
      setChecked(paraData.required);
      const metaData = paraData.metaData;
      setParaType(metaData.type);
      if (metaData.type === "INTEGER" || paraType === "FLOAT"){
        setMaxValue(metaData.max);
        setMinValue(metaData.min);
        setDefaultValue(metaData.default);
      }
      else if (paraType === "BOOLEAN"){
        setDefaultBool(metaData.default);
      }
      else if (paraType === "UTF8_STRING"){
        setDefaultStr(metaData.default);
        setMinLen(metaData.minLength);
        setMaxLen(metaData.maxLength);
      }
      else if (paraType === "LIST"){
        setDefaultList(metaData.default);
        setMinItems(metaData.minItems);
        setMaxItems(metaData.maxItems);
        setItemType(metaData.itemType);
      }
    }
  },[paraIndex]);

  const clearAllData = (): any => {
    setParaId("");
    setIdBeforeEdit("");
    setName("");
    setDescription("");
    setChecked(true);
    setDefaultValue(0);
    setMaxValue(50000);
    setMinValue(0);
    setParaType("INTEGER");
    setDefaultBool(false);
    setMaxLen(500);
    setMinLen(0);
    setDefaultStr("");
    setMaxItems(10);
    setMinItems(0);
    setDefaultList([]);
    setItemType("");

    setIdErrorText("");
    setNameErrorText("");
    setDefaultValueErrorText("");
    setMaxValueErrorText("");
    setMinValueErrorText("");
    setMaxLenErrorText("");
    setMinLenErrorText("");
    setDefaultStrErrorText("");
    setMaxItemsErrorText("");
    setMinItemsErrorText("");
    setDefaultListErrorText("");
    setItemTypeErrorText("");
  }

  const handleClose = (): any => {
    setAddParaOpen(false);
    setIndex(null);
    setParaIndex(null);
  };

  const handleSave = (): any => {
    !name ? setNameErrorText("Please enter input name") : setNameErrorText("");
    !paraId ? setIdErrorText("Please enter Id"):setIdErrorText("");
    !description ? setDescriptionErrorText("Please enter description"):setDescriptionErrorText("");
    //Check Id exists
    if(paraId != idBeforeEdit) {
      if (parameterIds.includes(paraId)) {
        setIdErrorText("Id already exists.");
        return;
      }
    }

    let metaData = {}
    if (paraType === "INTEGER" || paraType === "FLOAT"){
      !minValue ? setMinValueErrorText("Please enter min value") : setMinValueErrorText("");
      !maxValue ? setMaxValueErrorText("Please enter max value") : setMaxValueErrorText("");
      !defaultValue ? setDefaultValueErrorText("Please enter default value") : setDefaultValueErrorText("");
      metaData = {
        type: paraType,
        min: minValue,
        max: maxValue,
        default: defaultValue
      }
    }
    else if (paraType === "BOOLEAN"){
      metaData = {
        type: paraType,
        default: defaultBool
      }
    }
    else if (paraType === "UTF8_STRING"){
      !minLen && minLen != 0 ? setMinLenErrorText("Please enter min length") : setMinLenErrorText("");
      !maxLen ? setMaxLenErrorText("Please enter max length") : setMaxLenErrorText("");
      !defaultStr ? setDefaultStrErrorText("Please enter default string") : setDefaultStrErrorText("");
      if (maxLen && defaultStr) {
        metaData = {
          type: paraType,
          default: defaultStr,
          minLength: minLen,
          maxLength: maxLen
        }
      }
    }
    else if (paraType === "LIST"){
      !minItems && minItems != 0 ? setMinItemsErrorText("Please enter min items") : setMinItemsErrorText("");
      !maxItems ? setMaxItemsErrorText("Please enter max items") : setMaxItemsErrorText("");
      !defaultList.length ? setDefaultListErrorText("Please add default list") : setDefaultListErrorText("");
      !itemType ? setItemTypeErrorText("Please enter item type") : setItemTypeErrorText("");
      if (maxItems && defaultList.length && itemType) {
        metaData = {
          type: paraType,
          itemType: itemType,
          default: defaultList,
          minItems: minItems,
          maxItems: maxItems
        }
      }
    }

    if(!name || !paraId || !description || Object.keys(metaData).length === 0){
      toast.error("Please fill all required data before saving.");
      return;
    }

    const paraData = {
      id: paraId,
      name: name,
      description: description,
      checked: checked,
      metaData: metaData
    }
    handleSaveParameter(index, paraIndex, paraData);
    handleClose();
    clearAllData();
  };

  return (
    <div>
      <Dialog open={addParaOpen} onClose={handleClose}>
        <DialogTitle>Parameter</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Parameter details
          </DialogContentText>
          <RequiredTextField
            label="Id"
            autoFocus={true}
            value={paraId}
            helperText={idErrorText}
            onChange={(e:any) => setParaId(e.target.value)}
          />
          <ToolTipWithIcon title="Id of the parameter. E.g. 'domainName'" />
          <RequiredTextField
            label="Name"
            value={name}
            helperText={nameErrorText}
            onChange={(e:any) => setName(e.target.value)}
          />
          <ToolTipWithIcon title="External name of the parameter. E.g. 'Domain Name'" />
          <RequiredTextField
            label="Description"
            value={description}
            helperText={descriptionErrorText}
            onChange={(e:any) => setDescription(e.target.value)}
          />
          <FormControlLabel
            label="Required"
            control={<Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} />}
          />
          <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={paraType}
            label="Type"
            onChange={handleChangeType}
          >
            <MenuItem value={"INTEGER"}>INTEGER</MenuItem>
            <MenuItem value={"FLOAT"}>FLOAT</MenuItem>
            <MenuItem value={"BOOLEAN"}>BOOLEAN</MenuItem>
            <MenuItem value={"LIST"}>LIST</MenuItem>
            <MenuItem value={"UTF8_STRING"}>UTF8_STRING</MenuItem>
          </Select>
          {paraType === "INTEGER" || paraType === "FLOAT" || paraType === "UTF8_STRING"?
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="default"
                label="Default"
                type={paraType === "UTF8_STRING" ? "text" : "number"}
                variant="standard"
                value={paraType === "UTF8_STRING" ? defaultStr : defaultValue}
                error={!!defaultValueErrorText || !!defaultStrErrorText}
                helperText={defaultValueErrorText?defaultValueErrorText:defaultStrErrorText}
                onChange={e => handleChangeValue(e, "default")}
              />
              <ToolTipWithIcon title="default value" />
              <TextField
                autoFocus
                margin="dense"
                id="max"
                label={paraType === "UTF8_STRING" ? "Max Length" : "Max"}
                type="number"
                variant="standard"
                value={paraType === "UTF8_STRING" ? maxLen : maxValue}
                error={!!maxValueErrorText || !!maxLenErrorText}
                helperText={maxValueErrorText?maxValueErrorText:maxLenErrorText}
                onChange={e => handleChangeValue(e, "max")}
              />
              <ToolTipWithIcon title="largest number or length allowed" />
              <TextField
                autoFocus
                margin="dense"
                id="min"
                label={paraType === "UTF8_STRING" ? "Min Length" : "Min"}
                type="number"
                variant="standard"
                value={paraType === "UTF8_STRING" ? minLen : minValue}
                error={!!minValueErrorText || !! minLenErrorText}
                helperText={minValueErrorText?minValueErrorText:minLenErrorText}
                onChange={e => handleChangeValue(e, "min")}
              />
              <ToolTipWithIcon title="smallest number or length allowed" />
            </div>
         :null}
          {paraType === "BOOLEAN"?
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={defaultBool}
              onChange={e => setDefaultBool(e.target.value === "true")}
            >
              <InputLabel id="demo-simple-select-helper-label">Default Value</InputLabel>
              <FormControlLabel value="true" control={<Radio />} label="True" />
              <FormControlLabel value="false" control={<Radio />} label="False" />
            </RadioGroup>
          :null}
          {paraType === "LIST"?
            <div>
              <ToolTipWithIcon title="When defining a list, you can either specify a base type (defined above). Or specify a custom type" />
              <InputChip
                chips={defaultList}
                setChips={setDefaultList}
                label="Default List"
                placeholder="Add value to the list"
                error={!!defaultListErrorText}
                helperText={defaultListErrorText}
              />
              <div style={{ color:'red' }}>{defaultListErrorText!==""?defaultListErrorText:null}</div>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                id="itemType"
                label="Item Type"
                type="text"
                variant="standard"
                value={itemType}
                error={!!itemTypeErrorText}
                helperText={itemTypeErrorText}
                onChange={e => setItemType(e.target.value)}
              />
              <ToolTipWithIcon title="specify a custom type e.g. gradeName" />
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                id="maxItems"
                label="Max Items"
                type="number"
                variant="standard"
                value={maxItems}
                error={!!maxItemsErrorText}
                helperText={maxItemsErrorText}
                onChange={e => setMaxItems(parseInt(e.target.value))}
              />
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                id="min"
                label="Min Items"
                type="number"
                variant="standard"
                value={minItems}
                error={!!minItemsErrorText}
                helperText={minItemsErrorText}
                onChange={e => setMinItems(parseInt(e.target.value))}
              />
            </div>
          :null}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}