import React, { FunctionComponent } from 'react';
import {Button, DialogActions} from "@mui/material";

interface ConfirmationModalProps {
  cancel: any;
  index: any;
  deleteParameter: any;
  paraIndex: number;
}

export const DeleteParameterModal: FunctionComponent<ConfirmationModalProps> = (props) => {
  const deleteRow = ():any => {
    props.deleteParameter(props.index, props.paraIndex);
    props.cancel();
  };

  return (
    <React.Fragment>
      <DialogActions>
        <Button variant="outlined" onClick={props.cancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={deleteRow}>
          Delete
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};