import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  List,
  Divider,
  Paper,
  Typography, Button, Grid, Pagination
} from "@mui/material";
import {ProductsApi} from '../../api';
import {ToolTipWithIcon} from "../../components/Modals/ToolTipWithIcon";

export function EnginePageContent(): JSX.Element {
  const productId = useParams().product || "";

  const [enginesDevelopment, enginesTesting, enginesStable, enginesAllLevels] = ProductsApi.useGetEngines(productId);
  const [page, setPage] = React.useState(1);
  const [allEngines, setAllEngines] = React.useState([]);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const itemsPerPage = 10;
  const onPageChange = (event: React.ChangeEvent<unknown>, value: number):void => {
    setPage(value);
  };

  useEffect(() => {
    if(enginesDevelopment.data && enginesTesting.data && enginesStable.data && enginesAllLevels.data){
      const developmentEngines:any = enginesDevelopment.data;
      const testingEngines:any = enginesTesting.data;
      const stableEngines:any = enginesStable.data;
      const allLevelEngines:any = enginesAllLevels.data;
      const engines:any = []

      engines.push(...developmentEngines);
      engines.push(...testingEngines);
      engines.push(...stableEngines);
      engines.push(...allLevelEngines);
      setNoOfPages(Math.ceil(engines.length / itemsPerPage))
      engines.sort(function(a:any,b:any){
        return +new Date(b.created) - +new Date(a.created);
      });
      setAllEngines(engines);
    }
    else return;
  },[enginesDevelopment, enginesTesting, enginesStable, enginesAllLevels]);

  const downloadTxtFile = (engineName:string, operationName:string, interfaceSpecifications:any):any => {
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(interfaceSpecifications, null, 2)], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = engineName + '_' + operationName +  "_spec.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const listOperations = (engineDetails:any):any => {
    const engineName = engineDetails.name;
    return engineDetails.operations.map((operation:any) => (
      <Grid container  spacing={2} key={operation.id}>
        <Grid item sm={7}>
          <Typography>
            <b style={{ color: '#4d9d37' }}>{operation.internalName} </b>
            <b>(Build {operation.engineBuildId})</b>
          </Typography>
          <Typography>
            <b style={{ color: 'grey' }}>Id: {operation.id}</b>
          </Typography>
        </Grid>
        <Grid item sm={5}>
          <Button variant="contained" onClick={() => downloadTxtFile(engineName, operation.internalName, operation.interfaceSpecifications)}>
            Download Specification file
          </Button>
        </Grid>
        <br />
        <Divider variant="middle" />
      </Grid>
    ))
  }

  const listEngines = ():any => {
    if(allEngines.length){
      return allEngines
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map((engineDetails:any) => (
        <div key={engineDetails.id}>
          <br />
          <Grid container  spacing={2}>
            <Grid item sm={5}>
              <Typography>
                <b style={{ color: '#4d9d37' }}>{engineDetails.name}</b>
              </Typography>
              <Typography>
                <b style={{ color: 'grey' }}>Id: {engineDetails.id}</b>
              </Typography>
              <Typography>
                <i style={{ color: 'grey' }}>{engineDetails.releaseLevel}</i>
              </Typography>
              <Typography>
                <i style={{ color: 'grey' }}>{engineDetails.created}</i>
              </Typography>
            </Grid>
            <Grid item sm={7}>
              {listOperations(engineDetails)}
            </Grid>
          </Grid>
          <br />
          <Divider variant="middle" />
        </div>
        ));
    }
    else return null;

  }
  if (enginesDevelopment.isLoading || !enginesDevelopment.data || enginesTesting.isLoading ||
      !enginesTesting.data || enginesStable.isLoading || !enginesStable.data || enginesAllLevels.isLoading ||
      !enginesAllLevels.data) {
     return <CircularProgress />;
  }

  if (enginesDevelopment.error || enginesTesting.error || enginesStable.error ||enginesAllLevels.error) {
    return <Typography>An error occurred, please try again.</Typography>;
  }


  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 10, paddingBottom: 15, paddingTop: 5, position: "relative"}}>
          <List>
            <Grid container  spacing={2}>
              <Grid item sm={5}>
                <h4 style={{ color: '#4d9d37' }}>Engine
                  <ToolTipWithIcon title="The Engine represents a binary/code package that understands how to perform processing operations." />
                </h4>
              </Grid>
              <Grid item sm={7}>
                <h4 style={{ color: '#4d9d37' }}>Operations
                  <ToolTipWithIcon title="The Operation is a definition of a specific operation that an engine can perform" />
                </h4>
              </Grid>
            </Grid>
            {listEngines()}
          </List>
          <Divider />
          <Box component="span">
            <Pagination
              count={noOfPages}
              page={page}
              onChange={onPageChange}
              defaultPage={1}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
}
