import React from 'react';
import { Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { maxFolderDepth } from "../../../Constants";
import { useGetPath } from "./GetItemPathHook";
import { RootBreadcrumb } from "./RootBreadcrumb";
import { FolderBreadcrumbs } from "./FolderBreadcrumbs";
import {  useSearchParams } from "react-router-dom";

export function PathBreadcrumbBar(props: {rootFolderName: string, rootFolderLink: string,}): JSX.Element {
  const path = useGetPath();
  const [searchParams] = useSearchParams();
  const dataSourcePathPrefix = searchParams.has('path') ? searchParams.get('path') || '' : '';

  if (!path?.data) {
    return <RootBreadcrumb {...props} onlyBreadcrumb={true} />;
  }

  if (path.hasNextPage && path.data.pages.length < maxFolderDepth && !path.isLoading) {
    // If the last retrieved folder in the path has a parent and isn't on the max folder depth, go up another layer
    path.fetchNextPage();
    return <RootBreadcrumb {...props} onlyBreadcrumb={true} />;
  }

  return (
    <div role="presentation" onClick={(event) => event.preventDefault()}>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        <RootBreadcrumb {...props} onlyBreadcrumb={false} />
        {/*{FolderBreadcrumbs(path.data.pages, dataSourceId)}*/}
      </Breadcrumbs>
    </div>
  );
}
