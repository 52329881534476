import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import DnsIcon from '@mui/icons-material/Dns';
import { productsListItemTypes } from "../../Constants";

interface ProductListItemProps {
  id: string,
  selected: boolean,
  name: string,
  HandleListItemClick: any,
  type: string,
}

export function ProductListItem(props: ProductListItemProps): JSX.Element {
  const navigate = useNavigate();

  function NavigateToItem(newItemId: string, itemType: string, productName: string): void {
    navigate('../product/' + newItemId, {
      state: {
        productName: productName,
      }
    });
  }

  let icon = <DnsIcon fontSize="large" color="info" />
  if (props.type === productsListItemTypes.PRODUCTS) {
    icon = <FolderIcon fontSize="large" color="info" />;
  }

  return (
    <ListItem key={props.name} disablePadding>
      <ListItemButton
        disableRipple
        selected={props.selected}
        onClick={(event) => props.HandleListItemClick(event, props.id)}
        onDoubleClick={() => NavigateToItem(props.id, props.type, props.name)}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={props.name} />
      </ListItemButton>
    </ListItem>
  );
}
