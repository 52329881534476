import {
  QueriesResults, QueryObserverResult,
  useMutation,
  UseMutationResult,
  useQueries, useQuery,
  UseQueryOptions,
} from 'react-query';
import apis from "./api.js";
import {_RetryIfNotAuthProblem, _ThrowErrorIfUnauthorised} from "./ErrorHandlingUtil";

const GetProductsQueryOptions = (isEnabled: boolean): UseQueryOptions => {
  return {
    queryKey: 'GetProducts',
    queryFn: () => {
      return apis.GetProducts().then((result: any) => {
        return result.data.items;
      })
    },
    enabled: isEnabled,
    staleTime: 30000,
    retry: _RetryIfNotAuthProblem,
    useErrorBoundary: _ThrowErrorIfUnauthorised,
  };
}

const GetEnginesQueryOptions = (isEnabled: boolean, productId:string, releaseLevel:string): UseQueryOptions => {
  return {
    queryKey: 'GetEngines/' + releaseLevel + '/' + productId,
    queryFn: () => {
      return apis.GetEngines(productId, releaseLevel).then((result: any) => {
        return result.data.items;
      })
    },
    enabled: isEnabled,
    staleTime: 30000,
    retry: _RetryIfNotAuthProblem,
    useErrorBoundary: _ThrowErrorIfUnauthorised,
  };
}

const GetInstanceConfigsQueryOptions = (isEnabled: boolean, provider:string): UseQueryOptions => {
  return {
    queryKey: 'GetInstanceConfigs',
    queryFn: () => {
      return apis.GetInstanceConfigs(provider).then((result: any) => {
        return result.data.items;
      })
    },
    enabled: isEnabled,
    staleTime: 30000,
    retry: _RetryIfNotAuthProblem,
    useErrorBoundary: _ThrowErrorIfUnauthorised,
  };
}

const GetUserSelf = (): UseQueryOptions => {
  return {
    queryKey: ['GetUserSelf'],
    queryFn: () => {
      return apis._MaptekAccountGetJson(`/rest/001/main/users/self/`);
    },
    useErrorBoundary: true,
    retry: _RetryIfNotAuthProblem,
  };
}

const GetTaskLogsQueryOptions = (isEnabled: boolean, taskId:string, nextToken:any=null): UseQueryOptions => {
  return {
    queryKey: 'GetLogs',
    queryFn: () => {
      return apis.GetTaskLogs(taskId, nextToken).then((result: any) => {
        return result.data;
      })
    },
    enabled: isEnabled,
    staleTime: 30000,
    retry: _RetryIfNotAuthProblem,
    useErrorBoundary: _ThrowErrorIfUnauthorised,
  };
}

export const ProductsApi = {
  useGetProducts: (): QueriesResults<any[]> => {
    const enabled = true
    return useQueries([
      GetProductsQueryOptions(enabled),
    ]);
  },
  useGetInstanceConfigsQueryOptions: (provider:string): QueriesResults<any[]> => {
    const enabled = true
    return useQueries([
      GetInstanceConfigsQueryOptions(enabled, provider),
    ]);
  },
  useGetEngines: (productId:string): QueriesResults<any[]> => {
    const enabled = true
    return useQueries([
      GetEnginesQueryOptions(enabled, productId, 'Development'),
      GetEnginesQueryOptions(enabled, productId, 'Testing'),
      GetEnginesQueryOptions(enabled, productId, 'Stable'),
      GetEnginesQueryOptions(enabled, productId, 'AllLevels')
    ]);

  },
  useValidateSpec: (): UseMutationResult<any> => {
    return useMutation(async (specParams: any) => {
      const specData = specParams.newSpec
      return apis.ValidateSpec(specData)
    });
  },
  useGetPostUri: (): UseMutationResult<any> => {
    return useMutation(async (fileParams: any) => {
      return apis.GetPostUri(fileParams.newFile)
    });
  },
  useCreateEngineAndOperation: (): UseMutationResult<any> => {
    return useMutation(async (engineParams: any) => {
      const engineData = engineParams.newEngine;
      return apis.CreateEngineAndOperation(engineData.name,
                                           engineData.buildId,
                                           engineData.releaseLevel,
                                           engineData.productId,
                                           engineData.specification,
                                           engineData.fileName,
                                           engineData.internalName)
    });
  },

  useGetUserSelf: (): QueryObserverResult<any> => {
    return useQuery(GetUserSelf());
  },
  useGetTaskLogs: (taskId:string, nextToken:any=null): QueriesResults<any[]> => {
    const enabled = false
    return useQueries([
      GetTaskLogsQueryOptions(enabled, taskId, nextToken),
    ]);
  },
}
