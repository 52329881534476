import React from 'react';
import { Backdrop, CircularProgress } from "@mui/material";

export function LoadingSpinnerWithBackdrop(props: {displayLoadingSpinner: boolean}): JSX.Element {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 9999}}
      open={props.displayLoadingSpinner}
    >
    <CircularProgress
      color="inherit"
      sx={{
        position: 'absolute',
        margin: 'auto',
        zIndex: 10000,
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      }} />
    </Backdrop>
  );
}
