import {Button, Container, Nav, Navbar} from "react-bootstrap";
import logoSVG from "../../assets/images/core/maptek_logo.svg";
import logoPNG from "../../assets/images/core/maptek_logo.png";
import React from "react";

export function TopNavbar(): React.ReactElement {
  return (
    <Navbar
      className="navbar-default navbar-fixed-top navbar-main above-sidebar"
      data-align={'left'}
      collapseOnSelect
      expand="lg">
      <Container fluid className="container-menu">
        <Navbar.Brand className="brand" href="/">
          <img alt="Maptek" src={logoSVG} data-fallback={logoPNG} width="100%" height="100%" />
        </Navbar.Brand>
        <div className="navbar-header navbar-spacing">
          <Button
            type="button"
            data-toggle="collapse"
            data-target="#navbar-collapse-1"
            className="navbar-toggle"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </Button>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="navbar-menu navbar-collapse collapse">
          <Nav className="me-auto">
            <Nav.Link className="nav navbar-nav" href='/'>Home</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}