import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const NextStepModal: any = (props:any) => {
  const {open} = props;
  const navigate = useNavigate();
  const redirect = ():any => {
    navigate('/products/');
  };

  return (
    <Dialog open={open}>
      <DialogContent style={{padding: "15px"}}>
        <Typography id="confirm-modal-description">
          You can choose a product and then use the downloaded specification file with your engine code to create an engine. Click to redirect to product list or create more specification files.
        </Typography>
      </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={redirect}>
          Redirect to product list
        </Button>
      </DialogActions>
    </Dialog>
  );
};