import React from "react";
import {Box, Paper, Typography} from "@mui/material";

interface ProductListPageProps {
  rootProductDetails: RootProduct,
  productList: JSX.Element,
}

export function ProductListPage(props: ProductListPageProps): JSX.Element {

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, padding: 2 }} >
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 5, paddingLeft: 10}}>
          <h4>Products</h4>
          <Typography>Please choose a product to add your engine/operation to.</Typography>
        </Paper>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 10, paddingBottom: 15, paddingTop: 5, position: "relative"}}>
          {props.productList}
        </Paper>
      </Box>
    </Box>
  );
}
