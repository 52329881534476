import React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {CreateSpecificationPageContent} from "./CreateSpecificationPageContent";

export function SpecificationPage(): JSX.Element {

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, padding: 2 }}>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 5, paddingLeft: 10}}>
          <h4>Specification creator</h4>
          <Typography>A specification file is a file that describes the inputs and outputs required for an operation, as well as some of the required configuration</Typography>
          <Typography>By defining the operation upfront, MCF can validate the input files and run the engine on an instance of the specified category, and ensure the specified output file(s) are generated</Typography>
        </Paper>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <CreateSpecificationPageContent />
      </Box>
    </Box>
  );
}
