import React from 'react';
import TextField from "@mui/material/TextField";

export const RequiredTextField: any = (props:any) => {
  const {value, label, helperText, onChange, InputLabelProps, InputProps, fullWidth, autoFocus} = props;

  return (
    <TextField
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      margin="dense"
      id={value}
      label={label}
      type="text"
      variant="standard"
      required
      value={value}
      error={!!helperText}
      helperText={helperText}
      onChange={onChange}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
    />
  );
};