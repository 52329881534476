import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box, Checkbox,
  Chip,
  InputLabel, ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import {InputChip} from "../Panel/InputChip";
import {useEffect} from "react";
import AccessCheck from "./AccessCheck";
import {EngineToolStepper} from "../Panel/EngineToolStepper";
import {ToolTipWithIcon} from "./ToolTipWithIcon";
import {BasicAdvancedRadioGroup} from "./BasicAdvancedRadioGroup";
import {RequiredTextField} from "./RequiredTextField";

const textStyle = {style: {fontSize: 17}}

export default function FileInputModal(props:any):any {
  const {open, setOpen, data, paraStoreData, index, setIndex, handleSaveFile} = props;
  const [fileId, setFileId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [maxSize, setMaxSize] = React.useState("");
  const [dataContainerTypes, setDataContainerTypes] = React.useState<any>([]);
  const [fileExtensions, setFileExtensions] = React.useState([]);
  const [descriptionErrorText, setDescriptionErrorText] = React.useState("");
  const [idErrorText, setIdErrorText] = React.useState("");
  const [nameErrorText, setNameErrorText] = React.useState("");
  const [typeErrorText, setTypeErrorText] = React.useState("");
  const [extensionErrorText, setExtensionErrorText] = React.useState("");
  const [maxErrorText, setMaxErrorText] = React.useState("");
  const [idBeforeEdit, setIdBeforeEdit] = React.useState("");
  const [nameBeforeEdit, setNameBeforeEdit] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [mode, setMode] = React.useState('basic');

  const [clientAccess, setClientAccess] = React.useState({
    readable: true,
    writable: true,
    assetReadable: true,
    assetWritable: true
  });
  const [taskAccess, setTaskAccess] = React.useState({
    readable: true,
    writable: true,
    assetReadable: true,
    assetWritable: true
  });

  useEffect(() => {
    if(index == null){
      clearAllData();
    }
    else {
      const rowData = data[index];
      setFileId(rowData.id);
      setIdBeforeEdit(rowData.id);
      setNameBeforeEdit(rowData.name);
      setName(rowData.name);
      setDescription(rowData.description);
      setMaxSize(rowData.maxSize);
      setDataContainerTypes(rowData.inputContainerTypes);
      setFileExtensions(rowData.fileExtensions);
      setClientAccess(rowData.access.client);
      setTaskAccess(rowData.access.task);
      setMode(rowData.mode);
      setActiveStep(1);
    }
  },[index]);

  const clearAllData = (): any => {
    setFileId("");
    setIdBeforeEdit("");
    setNameBeforeEdit("");
    setName("");
    setDescription("");
    setMaxSize("");
    setDataContainerTypes([]);
    setFileExtensions([]);
    setNameErrorText("");
    setIdErrorText("");
    setMaxErrorText("");
    setTypeErrorText("");
    setDescriptionErrorText("");
    setExtensionErrorText("");
    setMode("basic");
    setActiveStep(0);
    setClientAccess({
      readable: true,
      writable: true,
      assetReadable: true,
      assetWritable: true
    });
    setTaskAccess({
      readable: true,
      writable: true,
      assetReadable: true,
      assetWritable: true
    });
  }

  const handleClose = (): any => {
    setOpen(false);
    setIndex(null);
  };

  const handleChange = (event: any): any => {
    const {
     target: { value },
   } = event;
   setDataContainerTypes(
     // On autofill we get a stringified value.
     typeof value === 'string' ? value.split(',') : value,
   );
  };

   const handleMaxSizeChange = (event: any):void => {
    setMaxSize(event.target.value);
  };

  const handleSave = (): any => {
    !name ? setNameErrorText("Please enter input name") : setNameErrorText("");
    !fileId ? setIdErrorText("Please enter Id"):setIdErrorText("");
    !description ? setDescriptionErrorText("Please enter description"):setDescriptionErrorText("");
    !maxSize ? setMaxErrorText("Please enter max size") : setMaxErrorText("");
    // Check if maxSize is a valid number
    isNaN(parseInt(maxSize)) && isNaN(parseFloat(maxSize)) ? setMaxErrorText("Please enter a valid number") : setMaxErrorText("");
    !dataContainerTypes.length ? setTypeErrorText("Please choose at least one data container type") : setTypeErrorText("");
    !fileExtensions.length ? setExtensionErrorText("Please add at least one file extension and press enter") : setExtensionErrorText("");

    // Add '.' to file extensions
    const extensionWithDot = fileExtensions.map((extension:string) => !extension.startsWith('.') ? '.' + extension : extension);

    //Check Id exists
    if(fileId != idBeforeEdit){
      const inputWithSameId = data.filter((inputData:any) => inputData.id === fileId);
      const paraInputWithSameId = paraStoreData.filter((paraData:any) => paraData.id === fileId);
      if(inputWithSameId.length || paraInputWithSameId.length){
        setIdErrorText("Id already exists.");
        return;
      }
    }

    //Check Name exists
    if(name != nameBeforeEdit) {
      const inputWithSameName = data.filter((inputData: any) => inputData.name === name);
      const paraInputWithSameName = paraStoreData.filter((paraData:any) => paraData.name === name);
      if (inputWithSameName.length || paraInputWithSameName.length) {
        setNameErrorText("Name already exists.");
        return;
      }
    }

    if(name && fileId && maxSize && description && dataContainerTypes.length && fileExtensions.length){
      const access = {
        client: clientAccess,
        task: taskAccess
      }
      const FileData = {
        id: fileId,
        name: name,
        description: description,
        maxSize: maxSize,
        inputContainerTypes: dataContainerTypes,
        fileExtensions: extensionWithDot,
        access: access,
        mode: mode
      }
      handleSaveFile(index, FileData);
      handleClose();
      clearAllData();
    }
  }
    const steps = [
  {
    label: 'Input data mode',
    description: `Please choose your input data mode: pick “Basic” or “Advanced”. Basic has pre-filled default info, Advanced has more settings that can be modified`,
  },
  {
    label: 'File input details',
    description: `Please enter input details.`,
  },
];

  const stepNames = {
    MODE: "MODE",
    DETAILS: "DETAILS",
  }

  const handleNext = ():any => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if(mode === "basic"){
        setDataContainerTypes(["CHUNKED_FILE"]);
        setMaxSize("1073741824");
      }
  };

  const handleBack = ():any => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function GetStepEnum(indexNumber:number):any {
    switch (indexNumber) {
      case 0:
        return stepNames.MODE;
      case 1:
        return stepNames.DETAILS;
    }
  }

  function IsNextButtonEnabled(indexNumber:number):any {
    return GetStepEnum(indexNumber) !== stepNames.DETAILS
  }

    function GetStepContent(step:string):any {
    switch (step) {
      case stepNames.MODE:
        return <BasicAdvancedRadioGroup mode={mode} setMode={setMode} />;
      case stepNames.DETAILS:
        return <>
          <RequiredTextField
            label="Id(Internal Name)"
            autoFocus={true}
            value={fileId}
            helperText={idErrorText}
            onChange={(e:any) => setFileId(e.target.value)}
            InputLabelProps={textStyle}
            InputProps={textStyle}
          />
          <ToolTipWithIcon title="Internal name of the input will be used for file uploading. E.g. 'CONFIG_DATA'" />

          <RequiredTextField
            label="Name(External Name)"
            value={name}
            helperText={nameErrorText}
            onChange={(e:any) => setName(e.target.value)}
            InputLabelProps={textStyle}
            InputProps={textStyle}
          />
          <ToolTipWithIcon title="External name of the input. E.g. 'Configuration Data'" />

          <RequiredTextField
            label="Description"
            fullWidth={true}
            value={description}
            helperText={descriptionErrorText}
            onChange={(e:any) => setDescription(e.target.value)}
            InputLabelProps={textStyle}
            InputProps={textStyle}
          />

          <InputLabel id="demo-simple-select-helper-label" style={{fontSize: textStyle.style.fontSize,  marginTop: "20px"}} >Data Container Types(multiple available)</InputLabel>

          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={dataContainerTypes}
            error={!!typeErrorText}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value:any) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            <MenuItem value={"FILE"}>
              <Checkbox checked={dataContainerTypes.indexOf("FILE") > -1} />
              <ListItemText primary="FILE" />
            </MenuItem>
            <MenuItem value={"CHUNKED_FILE"}>
              <Checkbox checked={dataContainerTypes.indexOf("CHUNKED_FILE") > -1} />
              <ListItemText primary="CHUNKED_FILE" />
            </MenuItem>
            <MenuItem value={"MALAWI"}>
              <Checkbox checked={dataContainerTypes.indexOf("MALAWI") > -1} />
              <ListItemText primary="MALAWI" />
            </MenuItem>
          </Select>
          <span style={{ color:'red' }}>{typeErrorText!==""?typeErrorText:null}</span>
          <div>
            <InputChip
              chips={fileExtensions}
              setChips={setFileExtensions}
              label="File Extensions(*)"
              placeholder="Add file extensions(E.g. txt), press enter to add more"
              error={!!extensionErrorText}
              helperText={extensionErrorText}
            />
            <div style={{ color:'red' }}>{extensionErrorText!==""?extensionErrorText:null}</div>
          </div>

          <InputLabel id="demo-simple-select-autowidth-label">Max Size</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={maxSize}
            onChange={handleMaxSizeChange}
            autoWidth
            label="Max Size"
          >
            <MenuItem value={104857600}>100Mb</MenuItem>
            <MenuItem value={524288000}>500Mb</MenuItem>
            <MenuItem value={1073741824}>1Gb</MenuItem>
            <MenuItem value={5368709120}>5Gb</MenuItem>
          </Select>
          <div style={{ color:'red' }}>{maxErrorText!==""?maxErrorText:null}</div>

          <ToolTipWithIcon title="Max file size in bytes." />
          <br />
          {mode === "basic"?null:
            <AccessCheck
              clientAccess={clientAccess}
              setClientAccess={setClientAccess}
              taskAccess={taskAccess}
              setTaskAccess={setTaskAccess}
            />
          }
          </>;

      default:
        return 'Unknown step';
    }
  }

  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={"md"} scroll={'paper'} >
        <DialogTitle>File Input</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add file input
          </DialogContentText>
        <EngineToolStepper
          activeStep={activeStep}
          firstStepName={stepNames.MODE}
          lastStepName={stepNames.DETAILS}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          GetStepEnum={GetStepEnum}
          GetStepContent={GetStepContent}
          enableNextButton={IsNextButtonEnabled}
        />

        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose()}>Cancel</Button>
          <Button variant="contained" onClick={()=>handleSave()}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}