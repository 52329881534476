import React, {useEffect} from "react";
import {
  Box,
  Button, Checkbox, FormGroup,
  Paper, TextField
} from "@mui/material";

import {LoadingSpinnerWithBackdrop} from "../../components/Modals";
import {FileInputPanel} from "../../components/Panel/FileInputPanel";
import {ParameterInputPanel} from "../../components/Panel/ParameterInputPanel";
import {RunTimeEstimationPanel} from "../../components/Panel/RunTimeEstimationPanel";
import {OutputPanel} from "../../components/Panel/OutputPanel";
import {ProductsApi} from "../../api";
import {InstanceCategory} from "../../components/Panel/InstanceCategory";
import {toast} from "react-toastify";
import {NextStepModal} from "../../components/Modals/NextStepModal";
import {EngineToolStepper} from "../../components/Panel/EngineToolStepper";
import {ToolTipWithIcon} from "../../components/Modals/ToolTipWithIcon";
import {RequiredTextField} from "../../components/Modals/RequiredTextField";
import DownloadConfirmationModal from "../../components/Modals/DownloadConfirmationModal";
import {ClearDataModal} from "../../components/Modals/ClearDataModal";

export function CreateSpecificationPageContent(): JSX.Element {
  const [instanceConfigs]:any = ProductsApi.useGetInstanceConfigsQueryOptions('AWS');
  const [specExternalName, setSpecExternalName] = React.useState("");
  const [displayLoadingSpinner, setDisplayLoadingSpinner] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [fileInputData, setFileInputData] = React.useState<any[]>([]);
  const [paraStoreInputData, setParaStoreInputData] = React.useState<any[]>([]);
  const [parameterIds, setParameterIds] = React.useState<any[]>([]);
  const [formula, setFormula] = React.useState<any[]>(['3600']);
  const [runTimeEstParameters, setRunTimeEstParameters] = React.useState<any[]>([]);
  const [outputs, setOutputs] = React.useState<any[]>([]);
  const [instanceCategories, setInstanceCategories] = React.useState<any[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [openClearDataModal, setOpenClearDataModal] = React.useState(false);
  const [system, setSystem] = React.useState("linux");
  const [inputType, setInputType] = React.useState({
    file: false,
    parameter: false,
  });
  const steps = [
  {
    label: 'Please choose your input data type',
    description: `Do you need files uploaded(File), and/or parameters specified by the end user(Parameter Store)?`,
  },
  {
    label: 'Please enter operation External Name',
    description: `Please enter external name of the operation. The name will be used for calling MCF to use this operation`,
  },
  {
    label: ' Please add file inputs',
    description:
      'Please add file inputs for this operation, here you can define what types of files can be uploaded as the inputs for your task',
  },
  {
    label: 'Please add parameter store inputs',
    description: `Please add parameter store with parameter inputs for this operation. Parameter store is a set of validated input variables. Here you can define the group of parameters and what types of parameters can be used as the inputs for your task.`,
  },
  {
    label: '[optional] Please add run time estimation',
    description: `Please choose your operation's default run time (optional).`,
  },
  {
    label: ' Please choose instance category',
    description:
      'Please choose the instance type that this operation can be run on',
  },
  {
    label: 'Please add outputs',
    description: `Please add data outputs for this operation, here you can define files that can be used to store the output data from your operation`,
  },
];

  const stepNames = {
    INPUT_TYPE: "INPUT_TYPE",
    EXTERNAL_NAME: "EXTERNAL_NAME",
    FILE_INPUTS: "FILE_INPUTS",
    PARAMETER_INPUTS: "PARAMETER_INPUTS",
    RUNTIME_EST: "RUNTIME_EST",
    INSTANCE_CAT: "INSTANCE_CAT",
    OUTPUTS: "OUTPUTS",
  }

  useEffect(() => {
    setInstanceCategories([])
  }, [inputType])

  const confirm = ():any => {
    if(!inputType.file){
        setFileInputData([]);
      }
    if(!inputType.parameter){
      setParaStoreInputData([]);
      setParameterIds([]);
    }
    setOpenClearDataModal(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const cancel = ():any => {
    setOpenClearDataModal(false);
  }

  const handleNext = ():any => {
    //Clear file or parameter data if INPUT_TYPE changed
    //If users add some inputs and back to the first step, then wrong click the checkbox, all inputs will be gone.
    //We should keep all the data until they click next step button.
    if(GetStepEnum(activeStep) === stepNames.INPUT_TYPE){
      if((!inputType.file && fileInputData.length) || (!inputType.parameter && paraStoreInputData.length)){
        setOpenClearDataModal(true);
        return;
      }
    }
    if(GetStepEnum(activeStep + 1) === stepNames.FILE_INPUTS && !inputType.file){
      setActiveStep(GetStepNum(stepNames.PARAMETER_INPUTS));
    }
    else if(GetStepEnum(activeStep + 1) === stepNames.PARAMETER_INPUTS && !inputType.parameter){
      setActiveStep(GetStepNum(stepNames.RUNTIME_EST));
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = ():any => {
    // If user chooses parameter input, and the previous step is for file input, jump to the external name step
    if(GetStepEnum(activeStep - 1) === stepNames.FILE_INPUTS && !inputType.file){
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    }
    // If user chooses file input, and the previous step is for parameter input, jump to the file input step
    else if(GetStepEnum(activeStep - 1) === stepNames.PARAMETER_INPUTS && !inputType.parameter){
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    setInputType({
      ...inputType,
      [event.target.name]: event.target.checked,
    });
  };

  function GetStepContent(step:string):any {
    switch (step) {
      case stepNames.INPUT_TYPE:
        return <>
          <FormGroup row style={{ display:'inline-flex', alignItems: 'center' }}>
            <Checkbox checked={inputType.file} onChange={(e) => handleChange(e)} name="file" /> File
            <ToolTipWithIcon title="Files uploaded as the operation input" />
            <Checkbox checked={inputType.parameter} onChange={(e) => handleChange(e)} name="parameter" /> Parameter Store
            <ToolTipWithIcon title="Validated parameters specified by the end user as the operation input" />
            </FormGroup>
            <br />
          </>;
      case stepNames.EXTERNAL_NAME:
        return <>
          <RequiredTextField
            label="Operation External Name"
            value={specExternalName}
            onChange={(e:any) => setSpecExternalName(e.target.value)}
          />
          <ToolTipWithIcon title="Specifying the external name of the operation. e.g. 'BlastMCF'" />
            <br />
          </>;

      case stepNames.FILE_INPUTS:
        return (<div>
          <FileInputPanel
              fileInputData={fileInputData}
              setFileInputData={setFileInputData}
              handleSaveFile={handleSaveFile}
              deleteFile={deleteFile}
              paraStoreInputData={paraStoreInputData}
            />
          </div>);

      case stepNames.PARAMETER_INPUTS:
        return (<div>
            <ParameterInputPanel
              paraStoreInputData={paraStoreInputData}
              parameterIds={parameterIds}
              setParaStoreInputData={setParaStoreInputData}
              saveParameterStore={handleSaveParameterStore}
              deleteParameterStore={deleteParameterStore}
              deleteParameter={deleteParameter}
              handleSaveParameter={handleSaveParameter}
              fileInputData={fileInputData}
              doesParaStoreHaveParameters={doesParaStoreHaveParameters}
            />
          <div style={{ color:'red' }}>{paraStoreInputData.length >0 && !doesParaStoreHaveParameters(paraStoreInputData)
          ?"Please click \"Add Parameter\" to add an parameter to a parameter store":null}</div>
        </div>);

      case stepNames.RUNTIME_EST:
        return <>
          <RunTimeEstimationPanel
              formula={formula}
              setFormula={setFormula}
              parameterIds={parameterIds}
              runTimeEstParameters={runTimeEstParameters}
              setRunTimeEstParameters={setRunTimeEstParameters}
            />
          </>;

      case stepNames.INSTANCE_CAT:
        return (<div>
          <InstanceCategory
              system={system}
              setSystem={setSystem}
              instanceConfigs={instanceConfigs}
              instanceCategories={instanceCategories}
              setInstanceCategories={setInstanceCategories}
            />
          </div>);

      case stepNames.OUTPUTS:
        return (<div>
            <OutputPanel
              outputs={outputs}
              setOutputs={setOutputs}
              handleSaveOutput={handleSaveOutput}
              deleteOutput={deleteOutput}
            />
            </div>);

      default:
        return 'Unknown step';
    }
  }

  const stepNumToEnum:any ={
    '0': stepNames.INPUT_TYPE,
    '1': stepNames.EXTERNAL_NAME,
    '2': stepNames.FILE_INPUTS,
    '3': stepNames.PARAMETER_INPUTS,
    '4': stepNames.RUNTIME_EST,
    '5': stepNames.INSTANCE_CAT,
    '6': stepNames.OUTPUTS
  }

  function GetStepEnum(index:number):string {
    return stepNumToEnum[index.toString()];
  }

  function GetStepNum(indexName:string):number {
    return parseInt(Object.keys(stepNumToEnum).find(key => stepNumToEnum[key] === indexName) as string);
  }

  function doesParaStoreHaveParameters(paraStoreData:any[]):boolean{
    const emptyParaStore = paraStoreData.filter(paraStore=>paraStore.parameters.length == 0);
    return !emptyParaStore.length;
  }

  function enableNextButton(index:number):boolean {
    if(GetStepEnum(index) === stepNames.INPUT_TYPE && (inputType.parameter || inputType.file)){
      return true;
    }
    if(GetStepEnum(index) === stepNames.EXTERNAL_NAME && specExternalName){
      return true;
    }
    if(GetStepEnum(index) === stepNames.FILE_INPUTS && fileInputData.length > 0){
      return true;
    }
    if(GetStepEnum(index) === stepNames.PARAMETER_INPUTS && paraStoreInputData.length > 0 && doesParaStoreHaveParameters(paraStoreInputData)){
      return true;
    }
    if(GetStepEnum(index) === stepNames.RUNTIME_EST){
      return true;
    }
    if(GetStepEnum(index) === stepNames.INSTANCE_CAT && instanceCategories.length > 0){
      return true;
    }

    else return false;
  }

  const downloadFile = (interfaceSpecification:any):any => {
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify([interfaceSpecification],null,4)], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = specExternalName + "_spec.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const createAndDownloadFile = ():any => {
    if(!specExternalName){
      toast.error('Please enter specification external name');
      return;
    }
    if(fileInputData.length == 0 && paraStoreInputData.length == 0){
      toast.error('Please add file inputs or parameter store inputs');
      return;
    }
    if(outputs.length == 0){
      toast.error('Please add an output');
      return;
    }
    if(instanceCategories.length == 0){
      toast.error('Please choose at least one instance category');
      return;
    }
    else{
      setDisplayLoadingSpinner(true);
      setBtnDisabled(true);

      const fileSpecifications:any = [];
      const inputs:any = [];
      fileInputData.forEach((file:any) => {
        const fileSpec:any = {
          fileExtensions: file.fileExtensions,
          id: file.id,
          maxSize: parseInt(file.maxSize)
        }
        const fileInput:any = {
          access: file.access,
          description: {
            "en-AU": file.description
          },
          files: [file.id],
          id: file.id,
          inputContainerTypes: file.inputContainerTypes,
          name: {
            "en-AU": file.name
          }
        }
        fileSpecifications.push(fileSpec);
        inputs.push(fileInput);
      });

      const parameterSpecifications:any = [];
      paraStoreInputData.forEach((paraStore:any) => {
        const parameters:any = [];
        paraStore.parameters.forEach((parameter:any) => {
          const paraSpec:any = {
            description: {
              "en-AU": parameter.description
            },
            metadata: parameter.metaData,
            name: {
              "en-AU": parameter.name,
            },
            id: parameter.id,
            required: parameter.required,
          };
          parameterSpecifications.push(paraSpec);
          parameters.push(parameter.id);
        });

        const paraInput:any = {
          access: paraStore.access,
          description: {
            "en-AU": paraStore.description
          },
          id: paraStore.id,
          inputContainerTypes: paraStore.inputContainerTypes,
          name: {
            "en-AU": paraStore.name
          },
          parameters: parameters
        }
        inputs.push(paraInput);
      });

      const outputsSpec:any = [];
      outputs.forEach((output:any) => {
        const outputData:any = {
          access: output.access,
          description: {
            "en-AU": output.description
          },
          fileExtension: output.fileExtension,
          id: output.id,
          name: {
            "en-AU": output.name
          },
        }
        outputsSpec.push(outputData);
      })

      const pickedInstances:any = instanceConfigs.data.filter(function (instance:any) {
        const categories:any = instanceCategories;
        return categories.includes(instance.instanceCategory);
      });
      const instanceOptions:any = [];
      pickedInstances.forEach((instance:any) => {
        const instanceData:any = {
          instanceCategory: instance.instanceCategory,
          provider: instance.provider
        }
        instanceOptions.push(instanceData);
      })

      const spec:any = {
        externalName: {
          "en-AU": specExternalName
        },
        fileSpecifications: fileSpecifications,
        parameterSpecifications: parameterSpecifications,
        inputs: inputs,
        outputs: outputsSpec,
        truths: [],
        requirements: [],
        runtimeEstimateSpecification:{
          formula: formula,
          parameterIds: runTimeEstParameters,
        },
        instanceOptions: instanceOptions,
        specificationFormat: "000002"
      }

      downloadFile(spec);
      setOpen(true);
      setOpenConfirmModal(false)
      setDisplayLoadingSpinner(false);
    }
  }

  const openDownloadModal = async (e:any):Promise<any>=> {
    e.preventDefault();
    setOpenConfirmModal(true);
  }

  const handleSaveFile = (index:any, fileData:any): any => {
    const fileInput = {
      id: fileData.id,
      name: fileData.name,
      description: fileData.description,
      maxSize: fileData.maxSize,
      inputContainerTypes: fileData.inputContainerTypes,
      fileExtensions: fileData.fileExtensions,
      access: fileData.access,
      mode: fileData.mode
    }
    if (index == null) {
      // add new input
      setFileInputData([...fileInputData, fileInput]);
    } else {
      // update existing input
      const newFileData = fileInputData;
      newFileData[index] = fileInput;
      setFileInputData(newFileData);
    }
  }

  const handleSaveParameterStore = (index:any, paraStoreData:any): any => {
    const paraStoreInput = {
        id: paraStoreData.id,
        name: paraStoreData.name,
        description: paraStoreData.description,
        inputContainerTypes: ["PARAMETER_STORE"],
        parameters: [],
        access: paraStoreData.access,
        mode: paraStoreData.mode
      }
    if(index == null){
      // add new input
      setParaStoreInputData([...paraStoreInputData, paraStoreInput]);
    }
    else {
      // update existing input
      const newParaStoreData = paraStoreInputData;
      paraStoreInput.parameters = paraStoreInputData[index].parameters;
      newParaStoreData[index] = paraStoreInput;
      setParaStoreInputData(newParaStoreData);
    }
  };

  const handleSaveParameter = (index:any, paraIndex:any, paraData:any): any => {
    const parameter = {
      id: paraData.id,
      name: paraData.name,
      description: paraData.description,
      required: paraData.checked,
      metaData: paraData.metaData
    }
    const newData = paraStoreInputData;
    if(paraIndex == null){
      // add new parameter
      newData[index].parameters.push(parameter);
      setParameterIds([...parameterIds, parameter.id]);
      setParaStoreInputData(newData);
    }
    else {
      // update existing parameter
      const oldParaId = newData[index].parameters[paraIndex].id;
      newData[index].parameters[paraIndex] = parameter;
      const newParameterIds = parameterIds;
      const parameterIdIndex = newParameterIds.indexOf(oldParaId);
      setParameterIds([...parameterIds.slice(0, parameterIdIndex),
                       parameter.id,
                       ...parameterIds.slice(parameterIdIndex + 1)]);
      setParaStoreInputData(newData);
    }
  };

  const handleSaveOutput = (index:any, outputData:any): any => {
  const newOutput = {
      id: outputData.id,
      name: outputData.name,
      description: outputData.description,
      fileExtension: outputData.fileExtension,
      access: outputData.access,
      mode: outputData.mode
    }
  if(index == null){
    // add new input
    setOutputs([...outputs, newOutput]);
  }
  else {
    // update existing input
    const newData = outputs;
    newData[index] = newOutput;
    setOutputs(newData);
  }
};

  const deleteFile = (index:any): any => {
    const newFileData = fileInputData;
    newFileData.splice(index, 1);
    setFileInputData(newFileData);
  };

  const deleteOutput = (index:any): any => {
    const newOutputData = outputs;
    newOutputData.splice(index, 1);
    setOutputs(newOutputData);
  };

  const deleteParameterStore = (index:any): any => {
    const newParaStoreData = paraStoreInputData;
    let newParameterIds = parameterIds;
    newParaStoreData[index].parameters.forEach(function (para:any){
      const parameterId = para.id;
      newParameterIds = RemoveParameterId(newParameterIds, parameterId);
    })
    newParaStoreData.splice(index, 1);
    setParaStoreInputData(newParaStoreData);
    setParameterIds(prevParas =>{
      return prevParas.filter(id=>{
        if(newParameterIds.includes(id)){
          return id;
        }
      });
    });
  };

  const deleteParameter = (paraStoreIndex:any, paraIndex:any): any => {
    const paraStore = paraStoreInputData;
    let newParameterIds = parameterIds;
    const parameterId = paraStore[paraStoreIndex].parameters[paraIndex].id;
    paraStore[paraStoreIndex].parameters.splice(paraIndex, 1);
    newParameterIds = RemoveParameterId(newParameterIds, parameterId)
    setParaStoreInputData(paraStore);
    setParameterIds(prevParas =>{
      return prevParas.filter(id=>{
        if(newParameterIds.includes(id)){
          return id;
        }
      });
    });
  };

  const RemoveParameterId = (newParameterIds:any, parameterId:any): any => {
    return newParameterIds.filter((item:any) => parameterId != item);
  };

  const CloseDownloadConfirmationModal = (): void => {
    setOpenConfirmModal(false);
  };

  return (
    <>
      <LoadingSpinnerWithBackdrop displayLoadingSpinner={displayLoadingSpinner} />
      <ClearDataModal open={openClearDataModal} confirm={confirm} cancel={cancel} />
      <NextStepModal open={open} />
      <DownloadConfirmationModal
        isOpen={openConfirmModal}
        onCancel={CloseDownloadConfirmationModal}
        onDownload={createAndDownloadFile}
        externalName={specExternalName}
        fileInputData={fileInputData}
        paraStoreInputData={paraStoreInputData}
        formula={formula}
        outputs={outputs}
        instanceCategories={instanceCategories}
      />
      <Box component="main" sx={{ flexGrow: 1, padding: 2}}>
        <Paper elevation={2} sx={{padding: 10, paddingBottom: 15, paddingTop: 5, position: "relative"}}>
          <form >
            <EngineToolStepper
            activeStep={activeStep}
            firstStepName={stepNames.INPUT_TYPE}
            lastStepName={stepNames.OUTPUTS}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
            GetStepEnum={GetStepEnum}
            GetStepContent={GetStepContent}
            enableNextButton={enableNextButton}
        />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={btnDisabled}
              onClick={openDownloadModal}
            >
              Create
            </Button>
          </form>
        </Paper>
      </Box>
    </>
  );
}
