import React from "react";
import {
  Box,
  CircularProgress, Grid,
  Paper, Typography
} from "@mui/material";

import {ProductsApi} from "../../api";
import SortTable from "../../components/Panel/SortTable";


export function InstanceCategoryPageContent(): JSX.Element {
  const [instanceConfigs]:any = ProductsApi.useGetInstanceConfigsQueryOptions('AWS');
  const showLoadingOrError = (): any => {
    if (instanceConfigs.isLoading || !instanceConfigs.data) {
     return <CircularProgress />;
  }
    if (instanceConfigs.error) {
      return <Typography>An error occurred, please try again.</Typography>;
    }
  }

  const RenderCategories = (): any => {
    if (instanceConfigs.isLoading || !instanceConfigs.data) {
      return;
    }
    if (instanceConfigs.error) {
      return;
    }
    const instanceCategories:any = []
    instanceConfigs.data.forEach((instanceConfig:any) => {
      const latestVmData = instanceConfig.vmData[instanceConfig.vmData.length - 1]
      const system = latestVmData.isWindows ? "Windows" : "Linux"
      const instanceCategory = {
        instanceCategory: instanceConfig.instanceCategory,
        instanceType: instanceConfig.instanceType,
        system: system,
        imageId: latestVmData.supervisorImageId
      }
      instanceCategories.push(instanceCategory)
    })
    return <SortTable rows={instanceCategories} />
  };


  return (
    <>
       <Box component="main" sx={{ flexGrow: 1, padding: 2}}>
        <Paper elevation={2} sx={{padding: 10, paddingBottom: 15, paddingTop: 5, position: "relative"}}>
          <Grid container spacing={2} style={{display:"flex", alignItems:"center"}}>
            {showLoadingOrError()}
            {RenderCategories()}
          </Grid>
          </Paper>
      </Box>
    </>
  );
}
