import React from 'react';
import { ToastContainer } from 'react-toastify';

export function ToastNotificationContainer(): React.ReactElement {
  return (
    <ToastContainer
      theme='colored'
      position="top-center"
      autoClose={4000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={true}
      draggable={true}
      pauseOnHover={true}
    />
  );
}