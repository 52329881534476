import React from 'react';
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {ToolTipWithIcon} from "./ToolTipWithIcon";

export function BasicAdvancedRadioGroup(props:any): JSX.Element {
  const {mode, setMode} = props;
  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>):void => {
    const modeValue = (event.target as HTMLInputElement).value;
    setMode(modeValue);
  };

  return (
    <>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={mode}
        onChange={handleChangeValue}
        style={{ display:'inline-flex', alignItems: 'center' }}
        >
        <FormControlLabel value="basic" control={<Radio />} label="Basic" style={{ marginRight:'0', marginBottom: '0' }} />
        <ToolTipWithIcon title="Only basic info needed with pre-filled default info" />
        <FormControlLabel value="advanced" control={<Radio />} label="Advanced" style={{ marginRight:'0', marginBottom: '0', marginLeft: '10px' }} />
        <ToolTipWithIcon title="Advanced usage where more settings can be modified(e.g downloadable)" />
      </RadioGroup>
      <br />
    </>
  );
}
