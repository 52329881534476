import axios from 'axios'
import {baseMAapiUrl} from "../components/Constants";


axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';


function GetApiHostUrl(): any {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:5010'
  }
  const localHostName = window.location.hostname.replace("enginetool.", "");
  return window.location.protocol + '//api.' + localHostName;
}

function createHeader(): any {
  return {
    'Content-Type': 'application/json',
  };
}

const apis = {
  CreatePipeline(name) {
    const jsonStr = {
      name: name,
    };

    return axios.post(GetApiHostUrl() + '/rest/001/pipelines/', JSON.stringify(jsonStr), {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: true
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },

  GetPipelines() {
    return axios.get(GetApiHostUrl() + '/rest/001/pipelines/', {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: true
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },
  GetProducts() {
    return axios.get(GetApiHostUrl() + '/rest/001/products/available/', {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: true
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },

  GetEngines(productId, releaseLevel) {
    return axios.get(GetApiHostUrl() + '/rest/001/engines/' + productId + '/operations/' + releaseLevel + '/', {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: true
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },

  ValidateSpec(spec) {
    return axios.post(GetApiHostUrl() + '/rest/001/engines/operations/specificationValidation/', JSON.stringify(spec), {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: true
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },

  GetPostUri(fileInfo) {
    const jsonStr = {
      fileName: fileInfo.fileName,
      productId: fileInfo.productId,
      engineName: fileInfo.engineName,
      buildId: fileInfo.buildId,
      fileType: fileInfo.fileType
    };
    if (window.location.hostname === 'localhost') {
      return  Promise.resolve(
          {
            data: {items: 'fakeUri'},
            status: 200
      });
    }
    return axios.post(GetApiHostUrl() + '/rest/001/engines/uploadUrl/', JSON.stringify(jsonStr), {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: false
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },

  CreateEngineAndOperation(name, buildId, releaseLevel, productId, spec, fileName, internalName) {
    const jsonStr = {
      engineData: {
        name: name,
        buildId: buildId,
        releaseLevel: releaseLevel,
      },
      productId: productId,
      operations: [
        {
          internalName: internalName,
          spec: spec,
        }
      ],
      fileName: fileName,
    };

    return axios.post(GetApiHostUrl() + '/rest/001/engines/operations/create/', JSON.stringify(jsonStr), {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: false
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },

  async UploadZipFile(url: string, file: File) {
    if (window.location.hostname === 'localhost') {
      return Promise.resolve({status: 200});
    }
    const response = await axios.put(url, file, {
      'headers': {
        'Content-Type': file.type,
      },
    }).catch(err => {
      console.log(err);
      throw err;
    });
    return response;
  },

  GetInstanceConfigs(provider) {
    return axios.get(GetApiHostUrl() + '/rest/001/engines/instanceConfigurations/' + provider + '/', {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: true
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },

  _MaptekAccountGetJson(url: string): any {
  return axios.get(`${baseMAapiUrl}${url}`, {
      headers: {
        'Accept': 'application/json'
      },
      withCredentials: true,
    })
      .then((resp: any) => resp.data)
  },

  GetTaskLogs(taskId, nextToken=null) {
    return axios.get(GetApiHostUrl() + '/rest/001/tasks/' + taskId + '/logs/',  {
      withCredentials: true,
      headers: createHeader(),
      crossDomain: true,
      params: nextToken?{nextForwardToken: nextToken}:null
    }).catch(err => {
      console.log(err);
      throw err;
    });
  },
}
export default apis

