import React from 'react';
import {InputLabel, MenuItem, Select} from "@mui/material";


export function RunTimeEstimationPanel(props:any): JSX.Element {
  const {formula, setFormula} = props;
  //TODO:
  // Type formula with parameter ids
  // Add validation on formula in the spec validator

  const handleChange = (event: any):void => {
    setFormula([event.target.value]);
  };

  return (
    <div>
      <InputLabel id="demo-simple-select-autowidth-label">Runtime Estimation</InputLabel>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={formula}
        onChange={handleChange}
        autoWidth
        label="Runtime Estimation"
      >
        <MenuItem value={"1800"}>30 Minutes</MenuItem>
        <MenuItem value={"3600"}>1 hour</MenuItem>
        <MenuItem value={"10800"}>3 hours</MenuItem>
        <MenuItem value={"21600"}>6 hours</MenuItem>
      </Select>
    </div>
  );
}
