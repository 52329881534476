import React from "react";

interface UserContextType {
  authorised: boolean,
  isLoading: boolean,
  isError: boolean,
  user: any | null,
}
export const UserContext = React.createContext<UserContextType>({
  authorised: false,
  isLoading: false,
  isError: false,
  user: null,
});
