import React, {useEffect, useState} from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Box,
  Button, Chip, Grid, InputLabel, MenuItem,
  Paper, Select, TextField
} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom'
import JSZip from "jszip";
import {toast} from "react-toastify";
import {ProductsApi} from "../../api";
import apis from "../../api/api.js";
import { useNavigate } from 'react-router-dom';
import {LoadingSpinnerWithBackdrop} from "../../components/Modals";
import {EngineToolStepper} from "../../components/Panel/EngineToolStepper";
import {ToolTipWithIcon} from "../../components/Modals/ToolTipWithIcon";
import {RequiredTextField} from "../../components/Modals/RequiredTextField";

const steps = [
  {
    label: 'Please enter engine details',
    description: `Please enter your engine name, buildId and choose the release level.`,
  },
  {
    label: ' Please upload your operation specification',
    description:
      'Please upload your operation specification file, if you do not have one, create one here',
  },
  {
    label: 'Upload your engine',
    description: `Upload your engine zip file here.`,
  },
];


export function CreateEnginePageContent(): JSX.Element {
  const productId = useParams().product || "";
  const [name, setName] = React.useState("");
  const [internalName, setInternalName] = React.useState("");
  const [buildId, setBuildId] = React.useState("");
  const [releaseLevel, setReleaseLevel] = React.useState("Development");
  const [nameErrorText, setNameErrorText] = React.useState("");
  const [buildIdErrorText, setBuildIdErrorText] = React.useState("");
  const [internalNameErrorText, setInternalNameErrorText] = React.useState("");
  const [zipFile, setZipFile] = useState<File>();
  const [specification, setSpecification] = useState({});
  const [zipUploadErrorMsg, setZipUploadErrorMsg] = React.useState("");
  const [specUploadErrorMsg, setSpecUploadErrorMsg] = React.useState("");
  const [displayLoadingSpinner, setDisplayLoadingSpinner] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const startFileNames = ['start.sh', 'start.py', 'start.ps1'];
  const validateSpecMutator = ProductsApi.useValidateSpec().mutateAsync;
  const createEngineAndOperationMutator = ProductsApi.useCreateEngineAndOperation().mutateAsync;
  const getPostUriMutator = ProductsApi.useGetPostUri().mutateAsync;
  const navigate = useNavigate();
  const location:any = useLocation();
  const [activeStep, setActiveStep] = React.useState(0);
  const stepNames = {
    ENGINE_DETAIL: "ENGINE_DETAIL",
    UPLOAD_SPEC: "UPLOAD_SPEC",
    UPLOAD_ENGINE: "UPLOAD_ENGINE",
  }

  const handleNext = ():any => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = ():any => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let productName = ""
  if(location.state && location.state.hasOwnProperty('productName')){
    productName = location.state.productName;
  }
  let fileReader:any;

  function GetStepContent(step:string):any {
    switch (step) {
      case stepNames.ENGINE_DETAIL:
        return <>
          <RequiredTextField
            label="Name"
            value={name}
            helperText={nameErrorText}
            onChange={(e:any) => setName(e.target.value)}
          />
          <RequiredTextField
            label="BuildId"
            value={buildId}
            helperText={buildIdErrorText}
            onChange={(e:any) => setBuildId(e.target.value)}
          />
          <ToolTipWithIcon title="Please enter a buildId or version number" />
          <RequiredTextField
            label="Operation Internal Name"
            value={internalName}
            helperText={internalNameErrorText}
            onChange={(e:any) => setInternalName(e.target.value)}
          />
          <ToolTipWithIcon title="Operation internal name will be used to create a job" />

          <InputLabel id="demo-simple-select-helper-label">
            Release Level
            <ToolTipWithIcon title="Please choose a release level for operation" />
          </InputLabel>

          <Select
            labelId="demo-simple-select-helper-label"
            id="releaseLevel"
            value={releaseLevel}
            label="ReleaseLevel"
            onChange={e => setReleaseLevel(e.target.value)}
          >
            <MenuItem value={"Development"}>Development</MenuItem>
            <MenuItem value={"Testing"}>Testing</MenuItem>
            <MenuItem value={"Stable"}>Stable</MenuItem>
          </Select>
          <br />
          </>;

      case stepNames.UPLOAD_SPEC:
        return (<div>
          <div>
            <Link to="/specification"> [Create Specification] </Link>
          </div>
          <br />
          <Button variant="contained" component="label">
            <input hidden
              accept="application/json/*"
              type="file"
              onChange={handleSpecUpload} />
            <div>Upload spec.json</div>
          </Button>
          <div style={{marginTop:'10px'}}>
            {specUploadErrorMsg?
             <div>
               <Chip
                label="Spec invalid"
                color="error"
                variant="outlined"
                icon={<ErrorIcon />}
               />
               <div style={{ color:'red' }}> {specUploadErrorMsg}</div>
             </div>:null
             }
            {Object.keys(specification).length && !specUploadErrorMsg?
              <Chip
                label="Spec validated"
                color="primary"
                variant="outlined"
                icon={<DoneIcon />}
              />:null
            }
          </div>
          </div>);

      case stepNames.UPLOAD_ENGINE:
        return (<div>
          <Button variant="contained" component="label">
            <input hidden
              accept="application/zip/*"
              type="file"
              onChange={handleZipUpload}
            />
            <div>{zipFile ? `${zipFile.name}` : `Upload Zip File`} </div>
          </Button>
          <div style={{marginTop:'10px'}}>
            {zipUploadErrorMsg!==""?
              <div>
                <Chip
                  label="Zip file invalid"
                  color="error"
                  variant="outlined"
                 />
                <div style={{ color:'red' }}> {zipUploadErrorMsg}</div>
              </div>:null}
            {zipFile && !specUploadErrorMsg?
              <Chip
                label="Zip file validated"
                color="primary"
                variant="outlined"
                icon={<DoneIcon />}
              />:null
            }
          </div>
        </div>
        );

      default:
        return 'Unknown step';
    }
  }

  function handleBackButton():void{
    navigate('../product/' + productId, {
                    state: {
                      productName: productName,
                    }
                  });
  }

  const onSubmit = async (e:any):Promise<any>=> {
    setDisplayLoadingSpinner(true);
    setBtnDisabled(true);
    e.preventDefault();

    setNameErrorText(!name ? "Please enter engine name" : "");
    setInternalNameErrorText(!internalName ? "Please enter operation internal name" : "");
    setBuildIdErrorText(!buildId ? "Please enter buildId" : "");

    if(!zipFile || Object.keys(specification).length === 0){
      toast.error('Please upload zip file and specification file');
      return;
    }
    else if(zipUploadErrorMsg != "" || specUploadErrorMsg != ""){
      toast.error('Please upload valid zip file and specification file');
      return;
    }
    else if(name && buildId && releaseLevel && internalName){
      const newFile = {
        fileName: zipFile.name,
        productId: productId,
        engineName: name,
        buildId: buildId,
        fileType: zipFile.type
      }
      await getPostUriMutator({newFile: newFile}).then(async(result: any) => {
        if (result.status === 200 && result.data) {
          const uri = result.data;
          await apis.UploadZipFile(uri, zipFile).then(async(uploadResult: any) => {
            if(uploadResult.status === 200){
              const newEngine = {
              name:name,
              buildId: buildId,
              releaseLevel: releaseLevel,
              productId: productId,
              specification: specification,
              fileName: zipFile.name,
              internalName: internalName
            }
              await createEngineAndOperationMutator({newEngine: newEngine}).then((createResult: any) => {
                if (createResult.status === 200 && createResult.data) {
                  toast.success('Engine created');
                  setDisplayLoadingSpinner(false);
                  navigate('success/', {
                    state: {
                      engineData: {
                        name: name,
                        buildId: buildId,
                        releaseLevel: releaseLevel,
                        specification: specification,
                        productId: productId,
                        productName: productName,
                        internalName: internalName
                      },
                    }
                  });
                } else {
                  toast.error('Engine create failed');
                }
              }).catch((error:any) =>{
                if (error.response.data){
                  toast.error('Engine create failed: ' + error.response.data.message);
                }
                else {
                  toast.error('Engine create failed: ' + error.message);
                }
              });
            }
          }).catch((error:any) =>{
            toast.error('Zip file upload failed: ' + error.toString());
          })
        }
        else {
          toast.error('Get post url failed');
        }
      }).catch(function(err) {
          setZipUploadErrorMsg('Get post url failed： ' + err.toString());
      }).finally(() => {
        setDisplayLoadingSpinner(false);
        setBtnDisabled(false);
      });
    }
  }
  function GetStepEnum(index:number):any {
    switch (index) {
      case 0:
        return stepNames.ENGINE_DETAIL;
      case 1:
        return stepNames.UPLOAD_SPEC;
      case 2:
        return stepNames.UPLOAD_ENGINE;
    }
  }

  function enableNextButton(index:number):boolean {
    if(GetStepEnum(index) === stepNames.ENGINE_DETAIL && name && buildId && releaseLevel){
      return true;
    }
    if(GetStepEnum(index) === stepNames.UPLOAD_SPEC && Object.keys(specification).length != 0){
      return true;
    }
    return false;
  }

  function handleZipUpload(files:any):any {
    if (files.target.files.length > 0) {
      setZipUploadErrorMsg("");
      setDisplayLoadingSpinner(true);
      let isStartFileExist = false;
      JSZip.loadAsync(files.target.files[0]).then(function(zip) {
        for(const [fullFilename, file] of Object.entries(zip.files)) {
          const fileNameExtension = fullFilename.replace(/^.*[\\\/]/, '');
          if (startFileNames.includes(fileNameExtension) && isStartFileExist){
            setZipUploadErrorMsg('More than one start file in the zip file');
            setDisplayLoadingSpinner(false);
            setZipFile(undefined);
            break;
          }
          else if(startFileNames.includes(fileNameExtension)){
            isStartFileExist = true;
          }
        }
        if(!isStartFileExist){
        setZipUploadErrorMsg('No start file in the zip file');
        setDisplayLoadingSpinner(false);
        setZipFile(undefined);
        }
        else {
          setDisplayLoadingSpinner(false);
          toast.success('Zip file validated');
          setZipFile(files.target.files[0]);
        }
      }).catch(function(err) {
        setDisplayLoadingSpinner(false);
        setZipUploadErrorMsg("Failed to open Zip file: " + err.toString());
        setZipFile(undefined);
      });
    }
  }

  function handleSpecUpload(files:any):any {
    if (files.target.files.length > 0) {
      setDisplayLoadingSpinner(true);
      setSpecUploadErrorMsg("");
      setSpecification({});
      const specFile = files.target.files[0];
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(specFile);
    }
  }

  function handleFileRead(e:any):any {
    const content = fileReader.result;
    let contentJson:object;
    try {
      contentJson = JSON.parse(content);
    } catch (exc) {
      setSpecUploadErrorMsg("Please upload a valid specification json file (file doesn't appear to be the correct format)");
      setDisplayLoadingSpinner(false);
      setSpecification({});
      return;
    }
    validateSpecMutator({newSpec: contentJson}).then((result: any) => {
      if (result.status === 200 && result.data.isValid) {
        setSpecification(contentJson);
        setDisplayLoadingSpinner(false);
        toast.success('Specification file validated');
        } else {
          setDisplayLoadingSpinner(false);
          setSpecUploadErrorMsg('Specification file validation failed. Reason: ' + result.data.errorMsg);
          setSpecification({});
        }
      }).catch((error:any) =>{
          toast.error('Specification file validation failed. Reason: ' + error.response.data.message);
        });
    setSpecification(contentJson);
  }

  useEffect(() => {
    if(name!="" && buildId!="" && zipFile && Object.keys(specification).length > 0
        && zipUploadErrorMsg === "" && specUploadErrorMsg === ""){
      setBtnDisabled(false);
    }
    else {
      setBtnDisabled(true);
    }
  },[name, buildId, zipFile, specification, zipUploadErrorMsg, specUploadErrorMsg]);


  return (
    <>
      <LoadingSpinnerWithBackdrop displayLoadingSpinner={displayLoadingSpinner} />
      <Box component="main" sx={{ flexGrow: 1, padding: 2}}>
        <Paper elevation={2} sx={{padding: 10, paddingBottom: 15, paddingTop: 5, position: "relative", maxWidth: "60%"}}>
            <form>
          <Box sx={{ maxWidth: 400 }}>
        <EngineToolStepper
          activeStep={activeStep}
          firstStepName={stepNames.ENGINE_DETAIL}
          lastStepName={stepNames.UPLOAD_ENGINE}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          GetStepEnum={GetStepEnum}
          GetStepContent={GetStepContent}
          enableNextButton={enableNextButton}
        />

    </Box>
    <Grid container style={{justifyContent: "space-between", paddingTop: "10px"}}>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleBackButton}
        >
          Back to engine list
        </Button>
      </Grid>
      <Grid item>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={btnDisabled}
          onClick={onSubmit}
        >
          Upload
        </Button>
      </Grid>
    </Grid>
        </form>

        </Paper>
      </Box>
    </>
  );
}
