import * as React from 'react';
import {Checkbox, FormControlLabel, FormGroup, InputLabel} from "@mui/material";
import {ToolTipWithIcon} from "./ToolTipWithIcon";

export default function AccessCheck(props:any): any {
  const {clientAccess, setClientAccess} = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    setClientAccess({
      ...clientAccess,
      ['assetReadable']: event.target.checked,
    });
  };

  const accessChecks = (): any => {
    return <FormGroup row style={{ display:'inline-flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox checked={clientAccess.assetReadable} onChange={(e) => handleChange(e)} name="assetReadable" />
                }
                label="Downloadable"
              />
              <ToolTipWithIcon title={`Can client download the output file`} />
            </FormGroup>
  }

  return (
    <div>
      <InputLabel id="demo-simple-select-helper-label">Client Access</InputLabel>
        {accessChecks()}
    </div>
  );
}