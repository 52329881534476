import React, {useEffect} from "react";
import {Box, Button, Paper, Typography} from "@mui/material";
import { EnginePageContent } from './EnginePageContent'
import {useNavigate, useLocation, useParams} from "react-router-dom";

export function EnginePage(props: {rootProductDetails: RootProduct}): JSX.Element {
  let productNameOrId = useParams().product || "";
  let reRenderPage = false;
  const location:any = useLocation();
  if(location.state && location.state.hasOwnProperty('productName')){
    productNameOrId = location.state.productName;
    reRenderPage = true;
  }
  // Use useEffect to re-render page after navigating, print some log to avoid empty arrow eslint error.
  useEffect(() => {console.log("re-render page after navigating")},[reRenderPage]);
  const navigate = useNavigate();
  function NavigateToCreate(): void {
    navigate('create/', {
      state: {
        productName: productNameOrId,
      }
    });
  }
  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, padding: 2 }}>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 5, paddingLeft: 10}}>
          <h4>Products -&gt; {productNameOrId} -&gt; Engines</h4>
          <Typography>Create an engine by clicking the ADD ENGINE button on the right.</Typography>
          <Typography>All engines and operations are shown below.</Typography>
          <div style={{display: 'flex', justifyContent:'flex-end'}}>
            <Button variant={'contained'} color="primary" onClick={() => NavigateToCreate()}> + Add engine </Button>
          </div>
        </Paper>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <EnginePageContent />
      </Box>
    </Box>
  );
}
