import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import DnsIcon from "@mui/icons-material/Dns";

interface BreadcrumbItemProps {
  name: string,
  link: string,
  isLast: boolean,
  isDataSource: boolean,
}

export function BreadcrumbItem(props: BreadcrumbItemProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <Link
      key="/"
      underline="hover"
      onClick={() => navigate(props.link)}
      color={props.isLast ? "text.primary" : "inherit"}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {props.isDataSource &&
        <DnsIcon sx={{ mr: 0.5 }} fontSize="inherit" color="info" />
      }
      {props.name}
    </Link>
  );
}