import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Button} from "@mui/material";

function Row(props:any): any {
  const {row, dataType, openModal, index, setIndex, openAddParaModal,
         setParaIndex, openDeleteRowConfirmation, openDeleteParameterConfirmation} = props;
  const [open, setOpen] = React.useState(true);
  const editRow = (): any => {
    setIndex(index);
    openModal();
  }

  const addParameter = (): any => {
    setIndex(index);
    openAddParaModal();
  }

  const editParameter = (paraIndex:any): any => {
    setIndex(index);
    setParaIndex(paraIndex);
    openAddParaModal();
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }}} key={index}>
        {dataType === 'parameterStore'?
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open && row.parameters.length ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        :null}
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.name}</TableCell>
        {dataType === "outputs"?
          <TableCell align="right">{row.fileExtension}</TableCell>
          :  <TableCell align="right">{row.inputContainerTypes.map((type:any) => type + ', ')}</TableCell>}
        <TableCell align="center">
          {dataType === 'parameterStore'?
            <Button
              id="addParameter"
              variant="outlined"
              onClick={addParameter}
            >
            Add Parameter
            </Button>
          :null}
          <Button
            id="edit"
            variant="outlined"
            onClick={editRow}
          >
            Edit
          </Button>
          <Button
            id="delete"
            variant="outlined"
            onClick={()=>openDeleteRowConfirmation(index)}
          >
            Delete
          </Button>
        </TableCell>

      </TableRow>
      {dataType === 'parameterStore'?
        <TableRow>
          {/*TODO: Parameter store collapse line 95 - 127*/}
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open && row.parameters.length} timeout="auto" unmountOnExit >
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Parameters
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Type</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.parameters.map((parameter:any, paraIndex:any) => (
                      <TableRow key={parameter.id}>
                        <TableCell component="th" scope="row">
                          {parameter.id}
                        </TableCell>
                        <TableCell>{parameter.name}</TableCell>
                        <TableCell align="right">{parameter.metaData.type}</TableCell>
                        <TableCell align="center">
                          <Button
                            id="editPara"
                            variant="outlined"
                            onClick={() => editParameter(paraIndex)}
                          >
                            Edit
                          </Button>
                          <Button
                            id="deletePara"
                            variant="outlined"
                            onClick={() => openDeleteParameterConfirmation(index, paraIndex)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      :null
      }

    </React.Fragment>
  );
}

export default function CollapsibleTable(props:any): any {
  const {dataType, openModal, data, index, setIndex, openAddParaModal, paraIndex, setParaIndex, openDeleteRowConfirmation, openDeleteParameterConfirmation} = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {dataType === 'parameterStore'?
              <TableCell />
            :null}
            <TableCell align="center">Id</TableCell>
            <TableCell align="center">Name</TableCell>
            {dataType === 'outputs'?
              <TableCell align="center">File Extension</TableCell>
            :<TableCell align="center">Container Type</TableCell>}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row:any, dataIndex:any) => {
            if(dataType === 'parameterStore'){
              dataIndex = index;
            }
            return(
                 <Row index={dataIndex}
                   setIndex={setIndex}
                   row={row}
                   dataType={dataType}
                   openModal={openModal}
                   openAddParaModal={openAddParaModal}
                   paraIndex={paraIndex}
                   setParaIndex={setParaIndex}
                   openDeleteRowConfirmation={openDeleteRowConfirmation}
                   openDeleteParameterConfirmation={openDeleteParameterConfirmation}
                   key={dataIndex}
                />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}