import React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {InstanceCategoryPageContent} from "./InstanceCategoryPageContent";

export function InstanceCategoryPage(): JSX.Element {

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, padding: 2 }}>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 5, paddingLeft: 10}}>
          <h4>Instance Categories</h4>
          <Typography>You can choose an available instance category from the following table to use on
              specification creator. The instance category is the one you want to run your task on. </Typography>
        </Paper>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <InstanceCategoryPageContent />
      </Box>
    </Box>
  );
}
