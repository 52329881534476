import React from 'react';
import CollapsibleTable from "./TableComponent";
import {Button, InputLabel} from "@mui/material";
import {DeleteConfirmationModal} from "../Modals/DeleteConfirmationModal";
import OutputModal from "../Modals/OutputModal";
import {DeleteOutputModal} from "../Modals/DeleteOutputModal";

export function OutputPanel(props:any): JSX.Element {
  const {outputs, handleSaveOutput, deleteOutput} = props;
  const [outputModalOpen, setOutputModalOpen] = React.useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = React.useState(false);
  const [index, setIndex] = React.useState(null);
  const openModal = (): any => {
    setOutputModalOpen(true);
  }

  const openDeleteRowConfirmation = (newIndex: any): any => {
    setIndex(newIndex);
    setDeleteConfirmationModalOpen(true);
  }

  const cancelOpenDeleteRowConfirmation = (): any => {
    setDeleteConfirmationModalOpen(false);
    setIndex(null);
  };

  return (
    <>
      <OutputModal
        open={outputModalOpen}
        setOpen={setOutputModalOpen}
        data={outputs}
        index={index}
        setIndex={setIndex}
        handleSaveOutput={handleSaveOutput}
      />
      <DeleteConfirmationModal
        open={deleteConfirmationModalOpen}
        modalContent={
          <DeleteOutputModal
            index={index}
            cancel={cancelOpenDeleteRowConfirmation}
            deleteOutput={deleteOutput}
          />
        }
      />
      <Button
        id="addOutput"
        variant="outlined"
        onClick={openModal}
      >
        Add Outputs
      </Button>
      <br />
      <CollapsibleTable
        dataType="outputs"
        openModal={openModal}
        data={outputs}
        setIndex={setIndex}
        setOpenDeleteConfirm={setDeleteConfirmationModalOpen}
        openDeleteRowConfirmation={openDeleteRowConfirmation}
      />
    </>
  );
}
