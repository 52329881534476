import React from "react";
import {
    Box,
    Button, Grid,
    Paper
} from "@mui/material";

import {LoadingSpinnerWithBackdrop} from "../../components/Modals";
import {RequiredTextField} from "../../components/Modals/RequiredTextField";
import {LogList} from "./LogList";
import SearchIcon from '@mui/icons-material/Search';
import {toast} from "react-toastify";
import {ProductsApi} from "../../api";
import DownloadIcon from '@mui/icons-material/Download';


export function TaskLogPageContent(): JSX.Element {
  const [displayLoadingSpinner, setDisplayLoadingSpinner] = React.useState(false);
  const [taskId, setTaskId] = React.useState("");
  const [taskLogs, setTaskLogs] = React.useState([]);
  const [loading, setLoading] =  React.useState(false);
  const [hasMore, setHasMore] =  React.useState(false);
  const [nextToken, setNextToken] = React.useState(null);
  const [getTaskLog] = ProductsApi.useGetTaskLogs(taskId);
  const [getTaskLogWithToken] = ProductsApi.useGetTaskLogs(taskId, nextToken);

  const downloadFile = ():any => {
    if(!taskLogs.length){
      toast.error('Log is empty. Search with task Id and then download logs');
      return;
    }
    const logs = taskLogs.map((log:any) => log.message);
    const element = document.createElement("a");
    const file = new Blob([logs.join("\n")], {type: 'application/text'});
    element.href = URL.createObjectURL(file);
    element.download = taskId + "_logs.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  async function Search(): Promise<void> {
    if (!taskId) {
      toast.error('Please enter task Id');
      return;
    }
    setDisplayLoadingSpinner(true);
    await getTaskLog.refetch().then((result: any) => {
      if (result.status === "success" && result.data) {
          setTaskLogs(result.data.events);
          setNextToken(result.data.nextForwardToken);
          setHasMore(true);
      }
      else if(result.status === "error") {
        toast.error('Task Id not exist');
      }
    }).catch((error:any) =>{
      toast.error('Get task logs failed: ' + error.toString());
    }).finally(() => {
        setDisplayLoadingSpinner(false);
    });
  }

  async function LoadMore(): Promise<void> {
    if (loading) return;
    setLoading(true);
    await getTaskLogWithToken.refetch().then((result: any) => {
      if (result.status === "success" && result.data) {
        setTaskLogs((prev:any) => {
          const logs:any = [...prev, ...result.data.events];
          return logs;
        });
        if(result.data.nextForwardToken != nextToken){
          setNextToken(result.data.nextForwardToken);
          setHasMore(true);
        }else {
          setHasMore(false);
        }
      }
      else if(result.status === "error") {
        toast.error('Task Id not exist');
      }
    }).catch((error:any) =>{
      toast.error('Get task logs failed: ' + error.toString());
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <LoadingSpinnerWithBackdrop displayLoadingSpinner={displayLoadingSpinner} />
       <Box component="main" sx={{ flexGrow: 1, padding: 2}}>
        <Paper elevation={2} sx={{padding: 10, paddingBottom: 15, paddingTop: 5, position: "relative"}}>
          <Grid container spacing={2} style={{display:"flex", alignItems:"center"}}>
            <Grid item xs={4}>
                <RequiredTextField
                  fullWidth={true}
                  label="Task Id"
                  value={taskId}
                  onChange={(e:any) => {setTaskId(e.target.value); setNextToken(null)}}
                />
            </Grid>
            <Grid item xs={8}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={Search}
                  startIcon={<SearchIcon />}
                  style={{alignItems: 'center'}}
                >
                  Search
                </Button>

                <Button
                  type="submit"
                  disabled={!taskLogs.length}
                  variant="contained"
                  color="primary"
                  onClick={downloadFile}
                  startIcon={<DownloadIcon />}
                  sx={{ marginLeft: 1}}
                  style={{alignItems: 'center'}}
                >
                  Download Current Logs
                </Button>
            </Grid>
          </Grid>
            <LogList
              taskLogs={taskLogs}
              hasMore={hasMore}
              loading={loading}
              LoadMore={LoadMore}
            />
          </Paper>
      </Box>
    </>
  );
}
