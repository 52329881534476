import React from "react";
import {Box, Button, Grid, List, ListItem, Paper, Typography} from "@mui/material";
import { PathBreadcrumbBar } from "../../components/ProductManager/Navigation";
import {useNavigate, useLocation, Link} from "react-router-dom";
import FileCopyIcon from '@mui/icons-material/FileCopy';

export function EngineCreateSuccessPage(props: {rootProductDetails: RootProduct}): JSX.Element {
  let engineData:any;
  let command = "";
  const location:any = useLocation();
  const [copySuccess, setCopySuccess] = React.useState("");
  const navigate = useNavigate();

  if(location.state && location.state.hasOwnProperty('engineData')){
    engineData = location.state.engineData;
    const specification = engineData.specification[0];
    const operationName = engineData.internalName;
    const inputs = specification.inputs;
    const outputs = specification.outputs;
    let inputsCommand = "";
    let outputsCommand = "";
    inputs.forEach((input:any) => {
      const inputId = input["id"];
      inputsCommand = inputsCommand + ` --input ${inputId}=<fileName>`;
    });
    outputs.forEach((output:any) => {
      const outputId = output["id"];
      outputsCommand = outputsCommand + ` --output ${outputId}`;
    });

    command = `python3 -m mcf_public_api.cli -t <token> --operation ${operationName}` + inputsCommand + outputsCommand
  }
  else {
    navigate('../product/');
  }

  function NavigateToCreate(): void {
    navigate('../product/' + engineData.productId, {
                    state: {
                      productName: engineData.productName,
                    }
                  });
  }

  function copyToClipboard(): void {
    navigator.clipboard.writeText(command);
    setCopySuccess('Copied!');
  }

  function RenderItem(content: any, isCommand=false): any {
    if(!isCommand){
      return (
      <ListItem>
        <Typography
          color="textPrimary"
          style={{display: "inline-block", fontWeight: "bold"}}
        >
          {content}
        </Typography>
       </ListItem>
    )
    }
    else return (
      <ListItem>
        <Box component="span" sx={{
          display: 'block',
          p: 1,
          m: 1,
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          color: (theme) => theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          border: '1px solid',
          borderColor: (theme) => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
        }}>
          <Typography
            color="textPrimary"
            style={{display: "inline-block"}}
          >
          {content}
          </Typography>
          <Button variant="contained" startIcon={<FileCopyIcon />} onClick={copyToClipboard} >
           Copy
          </Button>
          <Typography
            color="textPrimary"
            style={{display: "flex", fontWeight: "bold", marginLeft: '15px', marginTop: '10px'}}
          >
            {copySuccess}
          </Typography>
        </Box>
       </ListItem>
    )
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, padding: 2 }}>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 5, paddingLeft: 10}}>
          <PathBreadcrumbBar
            rootFolderName={`${engineData.productName}/ Engines/ ${engineData.name}/ Create Successfully`}
            rootFolderLink={props.rootProductDetails.link}
          />
            <div style={{display: 'flex', justifyContent:'flex-end'}}>

            </div>
        </Paper>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 10, paddingBottom: 15, paddingTop: 5, position: "relative"}}>
          <Grid container spacing={1}>
            <Grid>
              <List dense={true}>

                {RenderItem(`Name: ${engineData.name}`)}
                {RenderItem(`BuildId: ${engineData.buildId}`)}
                {RenderItem(`Operation Internal Name: ${engineData.internalName}`)}
                {RenderItem(`Release Level: ${engineData.releaseLevel}`)}
                {RenderItem("Copy this command to run this operation with the python cli client:")}
                <ListItem>
                  <a href="https://help.mcf.testaccount.maptek.com/docs/external/How%20To%20Make%20an%20Engine/step3-running-your-engine-in-mcf" target="_blank" rel="noopener noreferrer">[Redirect to help page on how to install mcf cli client]</a>
                </ListItem>
                {RenderItem(command,true)}

              </List>
            </Grid>
      </Grid>
            <Button variant={'contained'} color="primary" onClick={() => NavigateToCreate()}> Back to engine list </Button>
        </Paper>
      </Box>
    </Box>
  );
}
