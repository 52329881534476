import React from "react";
import {CircularProgress, Typography} from "@mui/material";
import {ProductList} from "./ProductList";

export function RootProductList(props: {rootProductDetails: RootProduct}): JSX.Element {
    const [childProducts] = props.rootProductDetails.query();

  if (childProducts.isError) {
    return <Typography>An error occurred, please try again.</Typography>;
  }

  if (childProducts.isLoading || !childProducts.data) {
    return <CircularProgress />;
  }

  return <ProductList products={childProducts.data} />;

}
