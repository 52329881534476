import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect} from "react";
import AccessCheck from "./AccessCheck";
import {EngineToolStepper} from "../Panel/EngineToolStepper";
import {BasicAdvancedRadioGroup} from "./BasicAdvancedRadioGroup";
import {ToolTipWithIcon} from "./ToolTipWithIcon";
import {RequiredTextField} from "./RequiredTextField";

export default function ParameterStoreInputModal(props:any): any {
  const {open, setOpen, data, fileInputData, index, setIndex, saveParameterStore} = props;
  const [paraStoreId, setParaStoreId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [idErrorText, setIdErrorText] = React.useState("");
  const [nameErrorText, setNameErrorText] = React.useState("");
  const [descriptionErrorText, setDescriptionErrorText] = React.useState("");
  const [idBeforeEdit, setIdBeforeEdit] = React.useState("");
  const [nameBeforeEdit, setNameBeforeEdit] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [mode, setMode] = React.useState('basic');
  const [clientAccess, setClientAccess] = React.useState({
    readable: true,
    writable: true,
    assetReadable: true,
    assetWritable: true
  });
  const [taskAccess, setTaskAccess] = React.useState({
    readable: true,
    writable: true,
    assetReadable: true,
    assetWritable: true
  });

  useEffect(() => {
    if(index == null){
      clearAllData();
    }
    else {
      const rowData = data[index];
      setParaStoreId(rowData.id);
      setIdBeforeEdit(rowData.id);
      setNameBeforeEdit(rowData.name);
      setName(rowData.name);
      setDescription(rowData.description);
      setClientAccess(rowData.access.client);
      setTaskAccess(rowData.access.task);
      setMode(rowData.mode);
      setActiveStep(1);
    }
  },[index]);

  const clearAllData = (): any => {
    setParaStoreId("");
    setIdBeforeEdit("");
    setNameBeforeEdit("");
    setName("");
    setDescription("");
    setNameErrorText("");
    setIdErrorText("");
    setMode("basic");
    setActiveStep(0);
    setClientAccess({
      readable: true,
      writable: true,
      assetReadable: true,
      assetWritable: true
    });
    setTaskAccess({
      readable: true,
      writable: true,
      assetReadable: true,
      assetWritable: true
    });
  }

  const handleClose = (): any => {
    setOpen(false);
    setIndex(null);
  };

  const handleSave = (): any => {
    !name ? setNameErrorText("Please enter input name") : setNameErrorText("");
    !paraStoreId ? setIdErrorText("Please enter Id"):setIdErrorText("");
    !description ? setDescriptionErrorText("Please enter description"):setDescriptionErrorText("");
    //Check Id exists
    if(paraStoreId != idBeforeEdit){
      const inputWithSameId = data.filter((inputData:any) => inputData.id === paraStoreId);
      const fileInputWithSameId = fileInputData.filter((fileData:any) => fileData.id === paraStoreId);
      if(inputWithSameId.length || fileInputWithSameId.length){
        setIdErrorText("Id already exists.");
        return;
      }
    }

     //Check Name exists
    if(name != nameBeforeEdit) {
      const inputWithSameName = data.filter((inputData: any) => inputData.name === name);
      const paraInputWithSameName = fileInputData.filter((fileData:any) => fileData.name === name);
      if (inputWithSameName.length || paraInputWithSameName.length) {
        setNameErrorText("Name already exists.");
        return;
      }
    }

    if(name && paraStoreId && description){
      const access = {
        client: clientAccess,
        task: taskAccess
      }
      const paraStoreData = {
        id: paraStoreId,
        name: name,
        description: description,
        access: access,
        mode: mode
      }
      saveParameterStore(index, paraStoreData);
      clearAllData();
      handleClose();
    }
  }

  const steps = [
  {
    label: 'Input data mode',
    description: `Please choose your input data mode, pick “Basic” or “Advanced”.`,
  },
  {
    label: 'Parameter store details',
    description: `Please enter parameter store details.`,
  },
];

  const stepNames = {
    MODE: "MODE",
    DETAILS: "DETAILS",
  }

  const handleNext = ():any => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = ():any => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  function GetStepEnum(indexNumber:number):any {
    switch (indexNumber) {
      case 0:
        return stepNames.MODE;
      case 1:
        return stepNames.DETAILS;
    }
  }

  function IsNextButtonEnabled(indexNumber:number):any {
     return GetStepEnum(indexNumber) !== stepNames.DETAILS
  }

    function GetStepContent(step:string):any {
    switch (step) {
      case stepNames.MODE:
        return <BasicAdvancedRadioGroup mode={mode} setMode={setMode} />;
      case stepNames.DETAILS:
        return <>
          <RequiredTextField
            label="Id(Internal Name)"
            autoFocus={true}
            value={paraStoreId}
            helperText={idErrorText}
            onChange={(e:any) => setParaStoreId(e.target.value)}
          />
          <ToolTipWithIcon title="Internal name of the input. E.g. 'PARAMETER'" />
          <RequiredTextField
            label="Name(External Name)"
            value={name}
            helperText={nameErrorText}
            onChange={(e:any) => setName(e.target.value)}
          />
          <ToolTipWithIcon title="External name of the input. E.g. 'Blast parameter'" />
          <RequiredTextField
            label="Description"
            value={description}
            helperText={descriptionErrorText}
            onChange={(e:any) => setDescription(e.target.value)}
          />
          <br />
          {mode === "basic"?null:
            <AccessCheck
               clientAccess={clientAccess}
               setClientAccess={setClientAccess}
               taskAccess={taskAccess}
               setTaskAccess={setTaskAccess}
            />
          }
          </>;

      default:
        return 'Unknown step';
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Parameter Store</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add parameter store
          </DialogContentText>
          <EngineToolStepper
            activeStep={activeStep}
            firstStepName={stepNames.MODE}
            lastStepName={stepNames.DETAILS}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
            GetStepEnum={GetStepEnum}
            GetStepContent={GetStepContent}
            enableNextButton={IsNextButtonEnabled}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={()=>handleSave()}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}