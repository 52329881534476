import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ListItem, ListItemButton, ListItemIcon, ListItemText }  from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

export function SideNavBarItem(prop: {text: string, path: string}): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ListItem key={prop.text} disablePadding>
      <ListItemButton onClick={() => navigate(prop.path)}>
        <ListItemIcon>
          {location.pathname.startsWith(prop.path)
          ? <CircleIcon color="primary" />
          : <CircleOutlinedIcon />}
        </ListItemIcon>
        <ListItemText primary={prop.text} />
      </ListItemButton>
    </ListItem>
  );
}