import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect} from "react";
import AccessCheck from "./AccessCheck";
import {EngineToolStepper} from "../Panel/EngineToolStepper";
import {BasicAdvancedRadioGroup} from "./BasicAdvancedRadioGroup";
import {ToolTipWithIcon} from "./ToolTipWithIcon";
import {RequiredTextField} from "./RequiredTextField";

export default function OutputModal(props:any): any {
  const {open, setOpen, data, index, setIndex, handleSaveOutput} = props;
  const [outputId, setOutputId] = React.useState("");
  const [idBeforeEdit, setIdBeforeEdit] = React.useState("");
  const [nameBeforeEdit, setNameBeforeEdit] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [fileExtension, setFileExtension] = React.useState("");
  const [idErrorText, setIdErrorText] = React.useState("");
  const [nameErrorText, setNameErrorText] = React.useState("");
  const [descriptionErrorText, setDescriptionErrorText] = React.useState("");
  const [extensionErrorText, setExtensionErrorText] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [mode, setMode] = React.useState('basic');
  const [clientAccess, setClientAccess] = React.useState({
    readable: true,
    writable: true,
    assetReadable: true,
    assetWritable: true
  });
  const [taskAccess, setTaskAccess] = React.useState({
    readable: true,
    writable: true,
    assetReadable: true,
    assetWritable: true
  });

  useEffect(() => {
    if(index == null){
      clearAllData();
    }
    else {
      const rowData = data[index];
      setOutputId(rowData.id);
      setIdBeforeEdit(rowData.id);
      setName(rowData.name);
      setNameBeforeEdit(rowData.name);
      setDescription(rowData.description);
      setFileExtension(rowData.fileExtension);
      setClientAccess(rowData.access.client);
      setTaskAccess(rowData.access.task);
      setMode(rowData.mode);
      setActiveStep(1);
    }
  },[index]);

  const clearAllData = (): any => {
    setOutputId("");
    setIdBeforeEdit("");
    setNameBeforeEdit("");
    setName("");
    setDescription("");
    setFileExtension("");
    setNameErrorText("");
    setIdErrorText("");
    setExtensionErrorText("");
    setMode("basic");
    setActiveStep(0);
    setClientAccess({
      readable: true,
      writable: true,
      assetReadable: true,
      assetWritable: true
    });
    setTaskAccess({
      readable: true,
      writable: true,
      assetReadable: true,
      assetWritable: true
    });
  }

  const handleClose = (): any => {
    setOpen(false);
    setIndex(null);
  };

  const handleSave = (): any => {
    !name ? setNameErrorText("Please enter input name") : setNameErrorText("");
    !outputId ? setIdErrorText("Please enter Id") : setIdErrorText("");
    !description ? setDescriptionErrorText("Please enter description"):setDescriptionErrorText("");
    !fileExtension ? setExtensionErrorText("Please enter file extension") : setExtensionErrorText("");

    // Add '.' to file extensions
    const extensionWithDot = !fileExtension.startsWith('.') ? '.' + fileExtension : fileExtension;

    //Check Id exists
    if(outputId != idBeforeEdit) {
      const outputWithSameId = data.filter(function (outputData: any) {
        return outputData.id === outputId;
      });
      if (outputWithSameId.length) {
        setIdErrorText("Id already exists.");
        return;
      }
    }

    //Check Name exists
    if(name != nameBeforeEdit) {
      const outputWithSameName = data.filter(function (outputData: any) {
        return outputData.name === name;
      });
      if (outputWithSameName.length) {
        setNameErrorText("Name already exists.");
        return;
      }
    }

    if (name && outputId && fileExtension && description) {
      const access = {
        client: clientAccess,
        task: taskAccess
      }
      const outputData = {
        id: outputId,
        name: name,
        description: description,
        fileExtension: extensionWithDot,
        access: access,
        mode: mode
      }
      handleSaveOutput(index, outputData);
      handleClose();
      clearAllData();
    }
  }

  const steps = [
  {
    label: 'Output data mode',
    description: `Please choose your output data mode, pick “Basic” or “Advanced”.`,
  },
  {
    label: 'File output details',
    description: `Please enter output details.`,
  },
];

  const stepNames = {
    MODE: "MODE",
    DETAILS: "DETAILS",
  }

  const handleNext = ():any => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = ():any => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  function GetStepEnum(indexNumber:number):any {
    switch (indexNumber) {
      case 0:
        return stepNames.MODE;
      case 1:
        return stepNames.DETAILS;
    }
  }

  function IsNextButtonEnabled(indexNumber:number):any {
    return GetStepEnum(indexNumber) !== stepNames.DETAILS
  }

  function GetStepContent(step:string):any {
  switch (step) {
    case stepNames.MODE:
      return <BasicAdvancedRadioGroup mode={mode} setMode={setMode} />;
    case stepNames.DETAILS:
      return <>
        <RequiredTextField
          label="Id"
          value={outputId}
          autoFocus={true}
          helperText={idErrorText}
          onChange={(e:any) => setOutputId(e.target.value)}
        />
        <ToolTipWithIcon title="Id of the output. E.g. 'COMPRESSED_RESULTS'" />

        <RequiredTextField
          label="Name"
          value={name}
          helperText={nameErrorText}
          onChange={(e:any) => setName(e.target.value)}
        />
        <ToolTipWithIcon title="External name of the output. E.g. 'Custom Binary Output'" />

        <RequiredTextField
          label="Description"
          fullWidth={true}
          value={description}
          helperText={descriptionErrorText}
          onChange={(e:any) => setDescription(e.target.value)}
        />

        <RequiredTextField
          label="File Extension"
          value={fileExtension}
          helperText={extensionErrorText}
          onChange={(e:any) => setFileExtension(e.target.value)}
        />
        <ToolTipWithIcon title="E.g. '.txt'" />
        <br />
        {mode === "basic" ? null :
        <AccessCheck
           clientAccess={clientAccess}
           setClientAccess={setClientAccess}
           taskAccess={taskAccess}
           setTaskAccess={setTaskAccess}
        />
        }
        </>;
    default:
      return 'Unknown step';
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Output</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add output
          </DialogContentText>
          <EngineToolStepper
            activeStep={activeStep}
            firstStepName={stepNames.MODE}
            lastStepName={stepNames.DETAILS}
            steps={steps}
            handleBack={handleBack}
            handleNext={handleNext}
            GetStepEnum={GetStepEnum}
            GetStepContent={GetStepContent}
            enableNextButton={IsNextButtonEnabled}
        />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}