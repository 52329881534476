import React from 'react';

// main CSS definitions
import './MuiClassNameSetup.js';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/malawi.css';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { NavigationView } from './pages/NavigationView';
import { grey } from '@mui/material/colors';
import {AuthErrorBoundary} from "./components/AuthErrorBoundary";

const queryClient = new QueryClient()

const theme = createTheme({
  typography: {
    fontSize: 23,
    h3: {
      fontSize: 40,
      color: "#4d9d37"
    },
    h4: {
      fontSize: 35,
      color: "#4d9d37"
    },
    h5: {
      fontSize: 28,
    },
  },
  palette: {
    primary: {
     main: "#4d9d37",
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
        head: {
          textAlign: "center",
          backgroundColor: grey[300],
          borderRight: "1px solid " + grey[100],
          borderBottom: "1px solid " + grey[200],
        },
      },
    },
  },
});

export default function App(): React.ReactElement {
  return (
    <>
      <div className="App">
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AuthErrorBoundary>
              <NavigationView />
            </AuthErrorBoundary>
          </QueryClientProvider>
        </ThemeProvider>
      </div>
    </>
  );
}
