import React, {FunctionComponent} from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';

export interface ModalProps {
  modalContent: JSX.Element;
  open: boolean;
}

export const DeleteConfirmationModal: FunctionComponent<ModalProps> = ({
  modalContent,
  open,
}) => {

  return (
    <Dialog open={open}>
      <DialogContent style={{padding: "15px"}}>
        <Typography id="confirm-modal-description">
          Would you like to delete?
        </Typography>
      </DialogContent>
        {modalContent}
    </Dialog>
  );
}
