import React from 'react';
import CollapsibleTable from "./TableComponent";
import {Button, InputLabel} from "@mui/material";
import {DeleteConfirmationModal} from "../Modals/DeleteConfirmationModal";
import ParameterStoreInputModal from "../Modals/ParameterStoreInputModal";
import ParameterModal from "../Modals/ParameterModal";
import {DeleteParameterModal} from "../Modals/DeleteParameterModal";
import {DeleteParameterStoreModal} from "../Modals/DeleteParameterStoreModal";

export function ParameterInputPanel(props:any): JSX.Element {
  const {paraStoreInputData, parameterIds, saveParameterStore, deleteParameterStore, deleteParameter, handleSaveParameter, fileInputData} = props;
  const [paraStoreModalOpen, setParaStoreModalOpen] = React.useState(false);
  const [addParaModalOpen, setAddParaModalOpen] = React.useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = React.useState(false);
  const [index, setIndex] = React.useState(null);
  const [paraIndex, setParaIndex] = React.useState(null);
  const openModal = (): any => {
    setParaStoreModalOpen(true);
  }

  const openAddParaModal = (): any => {
    setAddParaModalOpen(true);
  }

  const openDeleteRowConfirmation = (newIndex: any): any => {
    setIndex(newIndex);
    setDeleteConfirmationModalOpen(true);
  }

  const openDeleteParameterConfirmation = (newIndex: any, newParaIndex: any): any => {
    setIndex(newIndex);
    setParaIndex(newParaIndex);
    setDeleteConfirmationModalOpen(true);
  }

  const cancelOpenDeleteRowConfirmation = (): any => {
    setDeleteConfirmationModalOpen(false);
    setParaIndex(null);
    setIndex(null);
  };

  return (
    <>
      <ParameterStoreInputModal
        open={paraStoreModalOpen}
        setOpen={setParaStoreModalOpen}
        data={paraStoreInputData}
        index={index}
        setIndex={setIndex}
        saveParameterStore={saveParameterStore}
        fileInputData={fileInputData}
      />
      <ParameterModal
        addParaOpen={addParaModalOpen}
        setAddParaOpen={setAddParaModalOpen}
        data={paraStoreInputData}
        index={index}
        parameterIds={parameterIds}
        setIndex={setIndex}
        paraIndex={paraIndex}
        setParaIndex={setParaIndex}
        handleSaveParameter={handleSaveParameter}
      />

      <DeleteConfirmationModal
        open={deleteConfirmationModalOpen}
        modalContent={
          paraIndex != null?
            <DeleteParameterModal
              index={index}
              paraIndex={paraIndex}
              cancel={cancelOpenDeleteRowConfirmation}
              deleteParameter={deleteParameter}
          />:
            <DeleteParameterStoreModal
              index={index}
              cancel={cancelOpenDeleteRowConfirmation}
              deleteParameterStore={deleteParameterStore}
          />
        }
      />

      <Button
        id="addParaStore"
        variant="outlined"
        onClick={openModal}
      >
        Add Parameter Store
      </Button>
      <br />
        {paraStoreInputData.map((paraStore:any, storeIndex:any) => (
          <div key={storeIndex}>
              <CollapsibleTable
                key={storeIndex}
                dataType="parameterStore"
                openModal={openModal}
                data={[paraStore]}
                index={storeIndex}
                setIndex={setIndex}
                openAddParaModal={openAddParaModal}
                paraIndex={paraIndex}
                setParaIndex={setParaIndex}
                openDeleteRowConfirmation={openDeleteRowConfirmation}
                openDeleteParameterConfirmation={openDeleteParameterConfirmation}
              />
              <br />
          </div>
        ))}
    </>
  );
}
