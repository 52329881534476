import React from "react";
import { Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BreadcrumbItem } from "./BreadcrumbItem";

interface RootBreadcrumbProps {
  rootFolderName: string,
  rootFolderLink: string,
  onlyBreadcrumb: boolean,
}

export function RootBreadcrumb(props: RootBreadcrumbProps): JSX.Element {
  if (props.onlyBreadcrumb) {
    return (
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        <BreadcrumbItem name={props.rootFolderName} link={props.rootFolderLink} isLast={true} isDataSource={false} />
      </Breadcrumbs>
    );
  }

  return <BreadcrumbItem name={props.rootFolderName} link={props.rootFolderLink} isLast={true} isDataSource={false} />;
}