import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Box from "@mui/material/Box";
import { rootProducts } from "../../components/Constants";
import { ProductListPageSubRoutes } from "./ProductListPageSubRoutes";
import { ProductSideNavBar } from "../../components/ProductManager/Navigation";

export function ProductViewLayout(): JSX.Element {

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <ProductSideNavBar />
        <Routes>
          <Route index element={<Navigate replace to={rootProducts[0].link} />} />
          {rootProducts.map((item: any) => (
            <Route key={item.path} path={item.path} element={
              <ProductListPageSubRoutes rootProductDetails={item} />
            } />
          ))}
        </Routes>
      </Box>
    </div>
  )
}
