import React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {TaskLogPageContent} from "./TaskLogPageContent";

export function TaskLogPage(): JSX.Element {

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, padding: 2 }}>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 5, paddingLeft: 10}}>
          <h4>Task Log Search</h4>
          <Typography>Search task log with task Id</Typography>
        </Paper>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <TaskLogPageContent />
      </Box>
    </Box>
  );
}
