import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import { RootProductList } from "../../components/ProductManager/ProductLists";
import { ProductListPage } from "./ProductListPage";
import { EnginePage } from "../EngineViews/EnginePage";
import {CreateEnginePage} from "../CreateEngineView/CreateEnginePage";
import {EngineCreateSuccessPage} from "../CreateEngineView/EngineCreateSuccessPage";

export function ProductListPageSubRoutes(props: {rootProductDetails: RootProduct}): JSX.Element {
  return (
    <Box sx={{overflow: "hidden", minWidth: 300, width: "100%"}}>
      <Routes>
        <Route index element={
          <ProductListPage {...props} productList={<RootProductList {...props} />} />
        } />
          <Route
          path="/product/:product"
          element={<EnginePage {...props}   />}
        />
          <Route
          path="/product/:product/create"
          element={<CreateEnginePage {...props}   />}
        />
          <Route
            path="/product/:product/create/success"
            element={<EngineCreateSuccessPage {...props}   />}
          />
      </Routes>
    </Box>
  );
}
