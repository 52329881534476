import React from 'react';
import { Box, Drawer, Toolbar } from '@mui/material';

const drawerWidth = 220;

export function SideBar(props: {children: any}): JSX.Element {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', top: 30, zIndex: 900},
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        {props.children}
      </Box>
    </Drawer>
  );
}