import React from 'react';
import { MuiChipsInput } from 'mui-chips-input'

export function InputChip(props:any): JSX.Element {
  const {chips, setChips, label, placeholder} = props;

  const handleChange = (newChips:any): any => {
    setChips(newChips);
  }

  return (<MuiChipsInput
      fullWidth
      margin={"normal"}
      label={label}
      value={chips}
      onChange={handleChange}
      placeholder={placeholder}
      InputLabelProps={{style: {fontSize: 17}}}
      InputProps={{style: {fontSize: 17}}}
    />
  );
}
