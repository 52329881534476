import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastNotificationContainer } from '../components/ToastNotificationContainer';
import { Footer, TopNavbar, UserCrumbBar } from '../components/Navigation'
import { ProductViewLayout } from "./ProductViews";
import { UserContext } from "../components/common/Context"
import {ProductsApi} from "../api";
import {SpecificationViewLayout} from "./SpecificationViews/SpecificationViewLayout";
import {TaskLogViewLayout} from "./TaskLogView/TaskLogViewLayout";
import {InstanceCategoryViewLayout} from "./InstanceCategoryView/InstanceCategoryViewLayout";


export function NavigationView(): JSX.Element {
  const {data, isLoading, isError} = ProductsApi.useGetUserSelf();

  return (
    <>
      <UserContext.Provider value={{
        authorised: data !== undefined,
        user: data,
        isLoading: isLoading,
        isError: isError,
      }}>
        <BrowserRouter>
          <ToastNotificationContainer />
          <TopNavbar />

            <UserCrumbBar />
            <div className="hidden-xs hidden-sm" style={{ height: '15px' }} />
            <div className="mt-15">
              <div style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                <Routes>
                  <Route path="/*" element={<ProductViewLayout />} />
                  <Route path="/specification" element={<SpecificationViewLayout />} />
                  <Route path="/tasklog" element={<TaskLogViewLayout />} />
                  <Route path="/instanceCategories" element={<InstanceCategoryViewLayout />} />
                </Routes>
              </div>
            </div>
        </BrowserRouter>
      </UserContext.Provider>
      <Footer />
    </>
  );
}
