import React, {useEffect} from 'react';
import {
  Box,
  Chip,
  CircularProgress, FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput, Radio, RadioGroup,
  Select, Typography,
  useTheme
} from "@mui/material";

const defaultWindows = "C5_XLARGE_WINDOWS_TEST"
const defaultLinux = "TEST_LINUX_GENERAL"


function getStyles(name: string, runTimeEstParameters: readonly string[], theme: any): any {
  return {
    fontWeight:
      runTimeEstParameters.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function InstanceCategory(props:any): JSX.Element {
  const {system, setSystem, instanceConfigs, instanceCategories, setInstanceCategories} = props;

  const theme = useTheme();

  const handleChange = (event: any): any => {
    const value:any = event.target.value;
    setInstanceCategories(value);
  };

  const handleSystemChange = (event: any): any => {
    setSystem(event.target.value);
  };

  const showLoadingOrError = (): any => {
    if (instanceConfigs.isLoading || !instanceConfigs.data) {
     return <CircularProgress />;
  }
    if (instanceConfigs.error) {
      return <Typography>An error occurred, please try again.</Typography>;
    }
  }

  const refeshOptions = (): any => {
    if (instanceConfigs.isLoading || !instanceConfigs.data) {
      return;
    }
    if (instanceConfigs.error) {
      return;
    }
    if(system === "windows" && instanceCategories.length === 0){
      setInstanceCategories([defaultWindows]);
    }
    else if(system === "linux" && instanceCategories.length === 0){
      setInstanceCategories([defaultLinux]);
    }

    if (system != ""){
      const availableOptions:any = instanceConfigs.data.filter(function (instance:any) {
        const vmData:any = instance.vmData[0];
        if(system === "windows" && vmData['isWindows']){
          return true;
        }
        else if(system === "linux" && !vmData['isWindows']){
          return true;
        }
      });
      return availableOptions.map((instanceConfig:any) => (
        <MenuItem
          key={instanceConfig.instanceCategory}
          value={instanceConfig.instanceCategory}
          style={getStyles(instanceConfig.instanceCategory, instanceCategories, theme)}
        >
          {instanceConfig.instanceCategory}
        </MenuItem>))
    }
  };

  // clear instance categories if the type changes
  useEffect(() => {
    setInstanceCategories([]);
  }, [system])

  return (
    <>
      <InputLabel id="demo-simple-select-autowidth-label">Windows or Linux</InputLabel>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={system}
        onChange={handleSystemChange}
        autoWidth
        label="Windows or Linux"
      >
        <MenuItem value={"windows"}>Windows</MenuItem>
        <MenuItem value={"linux"}>Linux</MenuItem>
      </Select>

      <InputLabel id="demo-simple-select-autowidth-label">Instance Category</InputLabel>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={instanceCategories}
        onChange={handleChange}
        autoWidth
        label="Instance Category"
         input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                <Chip key={selected} label={selected} />
            </Box>
          )}
      >
        {showLoadingOrError()}
        {refeshOptions()}
      </Select>
    </>
  );
}
