import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from '@mui/material';
import DialogContentText from "@mui/material/DialogContentText";

const RuntimeMapping: options = {
  "1800": "30 Minutes",
  "3600": "1 Hour",
  "10800": "3 Hours",
  "21600": "6 Hours"
}

type options = {
    [key: string]: string
}

export default function DownloadConfirmationModal(props:any): any {
  const {isOpen, onCancel, onDownload, externalName, fileInputData, paraStoreInputData, formula, outputs, instanceCategories} = props;
  function GetRunTimeString(runtime:string):string{
    return RuntimeMapping[runtime];
  }
  return (
    <Dialog open={isOpen}>
      <DialogContent style={{padding: "15px"}}>
        <DialogContentText> Summary </DialogContentText>
        <Typography variant="subtitle2"> <span style={{fontWeight:'bold'}}> External Name: </span> {externalName} </Typography>
        {fileInputData.length? <Typography variant="subtitle2">
          <span style={{fontWeight:'bold'}}> File Inputs: </span> {fileInputData.map(function(item:any) {return item.name;}).toString()}
        </Typography> : null}
        {paraStoreInputData.length?
          <Typography variant="subtitle2">
            <span style={{fontWeight:'bold'}}> Parameter Inputs(Parameter store names with parameters): </span><br />
            {paraStoreInputData.map(function(paraStore:any) {
              return `${paraStore.name}: ${paraStore.parameters.map(function(parameter:any) {return parameter.name}).toString()}. `;
            })}
          </Typography> : null}
        <Typography variant="subtitle2"> <span style={{fontWeight:'bold'}}> Runtime estimation: </span> {GetRunTimeString(formula[0])} </Typography>
        <Typography variant="subtitle2"> <span style={{fontWeight:'bold'}}> Instance Category: </span> {instanceCategories[0]} </Typography>
        <Typography variant="subtitle2"> <span style={{fontWeight:'bold'}}> Outputs: </span> {outputs.map(function(item:any) {return item.name;}).toString()} </Typography>
        <br />
        <Typography id="confirm-modal-description">
          Would you like to download this specification file?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Back
        </Button>
        <Button variant="contained" onClick={onDownload}>
          Download Spec File
        </Button>
      </DialogActions>
    </Dialog>
  );
}