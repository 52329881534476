import React from "react";
import {ProductsApi} from "../api";

const envAuthUrl: string = process.env.REACT_APP_AUTH_BASE_URL as string;
const envBaseApiUrl: string = process.env.REACT_APP_API_BASE_URL as string;

export const localMAapiUrl: string = envAuthUrl ? envAuthUrl : "http://localhost:5002";
export const localApiUrl: string = envBaseApiUrl ? envBaseApiUrl : "http://localhost:5010";
export const localMAUrl: string = envBaseApiUrl ? envBaseApiUrl : "http://localhost:5001";

function GetBaseHostUrl(): string {
  const hostName = window.location.hostname
  return hostName.replace('enginetool.mcf.', '')
}

export const baseMAapiUrl: string = window.location.hostname.indexOf("localhost") === 0 ? localMAapiUrl : `https://api.${GetBaseHostUrl()}`;
export const baseApiUrl: string = window.location.hostname.indexOf("http://localhost") === 0 ? localApiUrl : 'https://api.' + window.location.hostname;
export const baseMAUrl: string = window.location.hostname.indexOf("localhost") === 0 ? localMAUrl : `https://${GetBaseHostUrl()}`;

export const maxFolderDepth = 5;

export const rootProducts = [
  {
    path: "/products/*",
    name: "Products",
    query: ProductsApi.useGetProducts,
    link: "/products/",
  },
  {
    path: "/specification/*",
    name: "Specification Creator",
    link: "/specification/",
  },
  {
    path: "/tasklog/*",
    name: "Task Log Search",
    link: "/tasklog/",
  },
  {
    path: "/instanceCategories/*",
    name: "Instance Categories",
    link: "/instanceCategories/",
  }
];

export const productsListItemTypes = {
  PRODUCTS: "products",
  FOLDER: "folder",
  FILE: "file",
};

export const actionMenuOptions = {
  OPEN: "open",
  DOWNLOAD: "download",
  VIEW: "view",
};
