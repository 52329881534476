import React from 'react';
import { Typography, List } from "@mui/material";
import { ProductListItem } from "./ProductListItem";
import { productsListItemTypes } from "../../Constants";

export function ProductList(props: {products: any}): JSX.Element {
  const [selectedId, setSelectedId] = React.useState("");

  function HandleListItemClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedFolderId: string,
  ): void {
    setSelectedId(selectedFolderId);
  }
  if (props.products.length === 0) {
    return <Typography>No products found.</Typography>;
  }

  return (
    <List>
       {[...props.products].sort((a, b) => a.name.localeCompare(b.name)).map((productDetails:any) => (
        <ProductListItem
          id={productDetails.id}
          selected={selectedId === productDetails.id}
          key={productDetails.id}
          name={productDetails.name}
          HandleListItemClick={HandleListItemClick}
          type={productsListItemTypes.PRODUCTS}
        />
      ))}
    </List>
  );
}
