export const _ThrowErrorIfUnauthorised = (error: any): boolean => {
  if (error.response !== undefined) {
    if (error.response.status !== undefined) {
      return error.response.status !== 401
    }
  }
  return error.statusCode !== 401
}

export const _RetryIfNotAuthProblem = (failureCount: number, error: any): boolean => {
  if (failureCount >= 2) {
    return false
  }

  if (error.response !== undefined) {
    if (error.response.status !== undefined) {
      return error.response.status !== 401
    }
  }
  return error.statusCode !== 401
}
