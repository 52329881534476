import React from 'react';
import {Box, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import Button from "@mui/material/Button";


export function EngineToolStepper(props:any): JSX.Element {
  const {activeStep, firstStepName, lastStepName, steps, handleBack, handleNext, GetStepEnum, GetStepContent, enableNextButton} = props;

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step:any, indexNumber:number) => (
                <Step key={step.label}>
                  <StepLabel
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{mb: 1}}>
                      <div>
                        {GetStepContent(GetStepEnum(activeStep))}
                        <Button
                          disabled={GetStepEnum(indexNumber) === firstStepName}
                          variant="outlined"
                          onClick={handleBack}
                          sx={{mt: 1, mr: 1}}
                        >
                          Back
                        </Button>
                        {GetStepEnum(indexNumber) === lastStepName ? null :
                            <Button
                                variant="contained"
                                disabled={!enableNextButton(indexNumber)}
                                onClick={handleNext}
                                sx={{mt: 1, mr: 1}}
                            >
                              Continue
                            </Button>
                        }
                      </div>
                    </Box>
                  </StepContent>
                </Step>
        ))}
      </Stepper>
    </>
  );
}
