import React from 'react';
import HelpIcon from "@mui/icons-material/Help";
import {Tooltip} from "@mui/material";

export function ToolTipWithIcon(props:any): JSX.Element {
  const {title} = props;

  return (
    <Tooltip title={title} >
      <HelpIcon  fontSize={"small"} />
    </Tooltip>
  );
}
