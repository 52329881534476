import React from "react";
import {Box, Button, Divider, List, ListItem, ListItemText} from "@mui/material";

export function LogList(props:any): JSX.Element {
    const {taskLogs, hasMore, loading, LoadMore} = props;

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, padding: 2 }}>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
           <List>
          {taskLogs.map((log:any, index:number) => (
            <>
              <ListItem key={index}>
                   <ListItemText
                    primary={log.message}
                  />
              </ListItem>
              <Divider variant="middle" />
            </>
          ))}

          {hasMore && (
          <Button variant="text" className="loading-more-btn" onClick={LoadMore}>
            {loading ? "Loading..." : "Load More"}
          </Button>
        )}
           </List>
      </Box>
    </Box>
  );
}
