import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";

export const ClearDataModal: any = (props:any) => {
  const {open, confirm, cancel} = props;

  return (
    <Dialog open={open}>
      <DialogContent style={{padding: "15px"}}>
        <Typography id="confirm-modal-description">
          You&apos;re going to clear the data if you unselect file or parameter store
        </Typography>
      </DialogContent>
        <DialogActions>
        <Button variant="outlined" onClick={cancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={confirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};