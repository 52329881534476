import React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {SideBar} from "./SideBar";
import {localMAUrl, rootProducts} from "./Constants";
import {TopNavbar, UserCrumbBar} from "./Navigation";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";


class AuthErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any): any {
    // Update state so the next render will show the fallback UI if this is a request with a 401 response.
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        return { hasError: true, error: error };
      }
    }

    return { hasError: false, error: null };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    // You can also log the error to an error reporting service (maybe sentry.io?)
    // logErrorToMyService(error, errorInfo);
    this.setState({
      error: error,
      hasError: true,
    })
  }

  RedirectToMaptekAccount(): void {
    const currentLocation = window.location.href;
    const currentHostname = window.location.hostname;
    const baseName: string = currentHostname.indexOf("localhost") === 0 ? localMAUrl : `https://${currentHostname.replace('enginetool.mcf.', '')}`;

    window.location.href = `${baseName}/auth/service/handover?next=${encodeURIComponent(currentLocation)}`
  }

  render(): JSX.Element {
    const {hasError} = this.state;
    if (hasError) {
      return (
        <>
          <TopNavbar />
          <UserCrumbBar />
          <div className="hidden-xs hidden-sm" style={{ height: '15px' }} />
          <div className="mt-15">
            <div style={{ paddingLeft: '1%', paddingRight: '1%' }}>
              <Box sx={{ display: 'flex' }}>
                <SideBar>
                  <List>
                    {rootProducts.map((item: any) => (
                      <ListItem key={item.name} disablePadding disabled >
                        <ListItemButton disabled={true}>
                          <ListItemIcon>
                            <CircleOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={item.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </SideBar>
                <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
                  <Paper elevation={2} sx={{padding: 10, paddingBottom: 15, paddingTop: 5, position: "relative"}}>
                    <Typography>Your Maptek Account authorisation has expired, please sign in again.</Typography>
                    <hr />
                    <Button variant="contained" onClick={() => this.RedirectToMaptekAccount()}>Sign in</Button>
                  </Paper>
                </Box>
              </Box>
            </div>
          </div>
        </>
      )
    }
    return this.props.children;
  }
}

export {
  AuthErrorBoundary,
}