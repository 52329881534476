import React from 'react';
import CollapsibleTable from "./TableComponent";
import {Button} from "@mui/material";
import FileInputModal from "../Modals/FileInputModal";
import {DeleteConfirmationModal} from "../Modals/DeleteConfirmationModal";
import {DeleteFileModal} from "../Modals/DeleteFileModal";

export function FileInputPanel(props:any): JSX.Element {
  const {fileInputData, handleSaveFile, deleteFile, paraStoreInputData} = props;
  const [fileModalOpen, setFileModalOpen] = React.useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = React.useState(false);
  const [index, setIndex] = React.useState(null);
  const openModal = (): any => {
    setFileModalOpen(true);
    return;
  }

  const openDeleteRowConfirmation = (newIndex:any): any => {
    setIndex(newIndex);
    setDeleteConfirmationModalOpen(true);
  }

  const cancelOpenDeleteRowConfirmation = (): any => {
    setDeleteConfirmationModalOpen(false);
    setIndex(null);
  };

  return (
    <>
      <FileInputModal
        open={fileModalOpen}
        setOpen={setFileModalOpen}
        data={fileInputData}
        paraStoreData={paraStoreInputData}
        index={index}
        setIndex={setIndex}
        handleSaveFile={handleSaveFile}
      />
      <DeleteConfirmationModal
        open={deleteConfirmationModalOpen}
        modalContent={
          <DeleteFileModal
            index={index}
            cancel={cancelOpenDeleteRowConfirmation}
            deleteFile={deleteFile}
          />
        }
      />
      <Button
        id="addFile"
        variant="outlined"
        onClick={openModal}
      >
        Add File
      </Button>
      <br />
      <CollapsibleTable
        dataType="file"
        openModal={openModal}
        data={fileInputData}
        setIndex={setIndex}
        setOpenDeleteConfirm={setDeleteConfirmationModalOpen}
        openDeleteRowConfirmation={openDeleteRowConfirmation}
      />
    </>
  );
}
