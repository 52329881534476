import React from "react";
import {Box, Paper, Typography} from "@mui/material";
import { CreateEnginePageContent } from './CreateEnginePageContent'
import {useLocation} from "react-router-dom";

export function CreateEnginePage(props: {rootProductDetails: RootProduct}): JSX.Element {
  const location:any = useLocation();
  let productName = ""
  if(location.state && location.state.hasOwnProperty('productName')){
    productName = location.state.productName;
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, padding: 2 }}>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <Paper elevation={2} sx={{padding: 5, paddingLeft: 10}}>
            <h4>Products -&gt; {productName} -&gt; Engines -&gt; Create</h4>
            <Typography>Please enter engine details and upload files to create your engine</Typography>
        </Paper>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, padding: 2 }}>
        <CreateEnginePageContent />
      </Box>
    </Box>
  );
}
